import React, { useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import "../../graphql/fragments"
import { StockContext } from "../../context/stockContext"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { Cover } from "../../components/cover/cover"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import { Section, Container } from "../../components/grid/grid"
import { PDFFold } from "../../components/pdf-fold/pdf-fold"
import { WpGroup } from "../../components/utils/render-functions"
import { StockSection } from "../../components/stock-section/stock-section"
import { HeadingBasic } from "../../components/heading/heading"
import { CardSlider } from "../../components/card-slider/card-slider"
import { AcfColCards } from "../../components/acf-col-cards"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_REPORTS = gql`
  query GetFiles {
    fileCategory(id: "annual", idType: SLUG) {
      slug
      filesPdf(first: 5) {
        edges {
          cursor
          node {
            title
            acfFile {
              company
              year
              file {
                guid
                mediaItemUrl
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`

const ComponentCustom = ({ data, reportsData, stockPrice, lang }) => {
  const commpareByYear = (a, b) => {
    const yearA = a.node.acfFile.year
    const yearB = b.node.acfFile.year

    let comparison
    if (yearA < yearB) {
      comparison = 1
    } else if (yearA > yearB) {
      comparison = -1
    }

    return comparison
  }

  if (data.attributes.text === "stocks") {
    return <StockSection stockPrice={stockPrice} />
  } else if (data.attributes.text === "file-annual-recent") {
    return (
      <Section className="bg-light overflow-x-hidden">
        <Container className="text-md-center">
          <h2 className="h2 mb-4 mb-md-5">
            {lang === "EN" ? "Annual Reports" : "Laporan Tahunan"}
          </h2>
          {/* PDF Fold Desktop */}
          <div className="pdf-fold-wrapper d-none d-md-flex">
            {reportsData &&
              reportsData.fileCategory &&
              reportsData.fileCategory.filesPdf.edges
                .sort(commpareByYear)
                .map((item, i) => {
                  return (
                    <PDFFold
                      key={`report-desktop-${i}`}
                      year={item.node.acfFile && item.node.acfFile.year}
                      title={item.node.title}
                      type="annual"
                      link={
                        item.node.acfFile &&
                        item.node.acfFile.file &&
                        item.node.acfFile.file.mediaItemUrl
                      }
                    />
                  )
                })}
          </div>
          {/* PDF Fold Mobile */}
          <div className="glide d-md-none" id="annualReport">
            <CardSlider showSmDown={2.5}>
              {reportsData &&
                reportsData.fileCategory &&
                reportsData.fileCategory.filesPdf.edges
                  .sort(commpareByYear)
                  .map((item, i) => {
                    return (
                      <li className="glide__slide" key={`report-mobile-${i}`}>
                        <PDFFold
                          year={item.node.acfFile && item.node.acfFile.year}
                          title={item.node.title}
                          type="annual"
                          link={
                            item.node.acfFile &&
                            item.node.acfFile.file &&
                            item.node.acfFile.file.mediaItemUrl
                          }
                        />
                      </li>
                    )
                  })}
            </CardSlider>
          </div>
          <div className="clearfix mt-3 mt-md-4">
            <Link
              to="/investors/annual-report"
              className="btn btn-link font-size-lg fx-underline"
            >
              {lang === "EN"
                ? "See All Annual Reports"
                : "Lihat Semua Laporan Tahunan"}
            </Link>
          </div>
        </Container>
      </Section>
    )
  }
}

const WpIconlistLink = ({ data }) => {
  const link = title => {
    switch (title.toLowerCase()) {
      case "corporate governance":
        return "/investors/corporate-governance"
      case "annual report":
        return "/investors/annual-report"
      case "annual report / circulars":
        return "/investors/annual-report"
      case "financial report":
        return "/investors/financial-reports"
      case "press release":
        return "/investors/press-release"
      case "information disclosure statements":
        return "/investors/information-disclosure-statement"
      default:
        return `/investors/${title
          .toLowerCase()
          .split(" ")
          .join("-")}`
    }
  }
  return (
    <section className="values values-link">
      <div className="row row-5">
        {data.acf !== undefined &&
          data.acf.list.map((item, i) => (
            <div key={`${item.title}-${i}`} className="col-lg-6">
              <Link
                className="value-item w-ic-angle-right"
                to={link(item.title)}
              >
                <img alt="img" src={item.icon && item.icon.sourceUrl} />
                <p className="font-size-lg d-none d-md-block">
                  {item.title && item.title}
                </p>
                <p className="font-size-lg d-md-none">
                  {item.title && item.title.length > 20
                    ? `${item.title.slice(0, 25)}..`
                    : item.title}
                </p>
              </Link>
            </div>
          ))}
      </div>
    </section>
  )
}

const InvestorsPage = location => {
  const { stock, setStock } = useContext(StockContext)

  useEffect(() => {
    let stockBSDE
    let stockDUTI
    let stockA26

    if (stock.bsde === 0 && stock.duti === 0 && stock.a26 === 0) {
      fetch(
        "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/BSDE.JK/1d",
        {
          method: "GET",
          headers: {
            "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
            "x-rapidapi-key": `${process.env.YAHOO_FINANCE_KEY}`,
          },
        }
      )
        .then(response => response.json())
        .then(responseJson => responseJson && (stockBSDE = responseJson))
        .then(() => {
          fetch(
            "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/DUTI.JK/1d",
            {
              method: "GET",
              headers: {
                "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
                "x-rapidapi-key": `${process.env.YAHOO_FINANCE_KEY}`,
              },
            }
          )
            .then(duti => duti.json())
            .then(dutiJson => dutiJson && (stockDUTI = dutiJson))
            .then(() => {
              fetch(
                "https://yahoo-finance15.p.rapidapi.com/api/yahoo/hi/history/A26.SI/1d",
                {
                  method: "GET",
                  headers: {
                    "x-rapidapi-host": "yahoo-finance15.p.rapidapi.com",
                    "x-rapidapi-key": `${process.env.YAHOO_FINANCE_KEY}`,
                  },
                }
              )
                .then(a26 => a26.json())
                .then(a26Json => a26Json && (stockA26 = a26Json))
                .then(() => {
                  if (stockBSDE && stockDUTI && stockA26) {
                    setStock({
                      bsde: stockBSDE,
                      duti: stockDUTI,
                      a26: stockA26,
                    })
                  }
                })
                .catch(err2 => console.log(err2))
            })
            .catch(err1 => console.log(err1))
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        pageBy(uri: "general-information") {
          title
          slug
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
            ...CoreEmbedYoutube
            ...AcfColumnedCards
          }
          translation(language: ID) {
            title
            slug
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
              ...CoreEmbedYoutube
              ...AcfColumnedCards
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const { data: annualReports } = useQuery(GET_REPORTS)

  const wordPress = data?.wordPress?.pageBy?.blocks
  const wordPressID =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const title = data?.wordPress?.pageBy?.title
  const titleId = data?.wordPress?.pageBy?.translation?.title
  const seo = data?.wordPress?.pageBy?.seo
  const cover =
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]
  const coverTitle = titleId || title

  const Blocks = ({ item, i }) => {
    switch (item.name) {
      case "acf/columned-cards":
        return <AcfColCards data={item} key={`${item.name}${i}`} />
      case "core/group":
        if (item.innerBlocks.find(x => x.name === "acf/iconlist")) {
          const valueItems = item.innerBlocks.find(
            x => x.name === "acf/iconlist"
          )
          return (
            <div key={`quick-access-${i}`} className="py-main">
              <div className="container container-md">
                <HeadingBasic
                  h2={
                    item.innerBlocks.find(x => x.name === "core/heading")
                      .attributes.content
                  }
                />
                <WpIconlistLink data={valueItems} />
              </div>
            </div>
          )
        } else {
          let bgGray = false
          item.innerBlocks.map(
            innerBlockItem =>
              innerBlockItem.name === "acf/iconlist" && (bgGray = true)
          )
          return (
            <div
              key={`${item?.name}-${i}`}
              className={`py-main ${bgGray ? "bg-light" : ""}`}
            >
              {item && <WpGroup data={item} /> }
            </div>
          )
        }
      case "core-embed/youtube":
        return (
          <div className="py-main">
            <Cover
              variant="basic"
              theme="dark"
              img={null}
              imgHeight="h-400px h-md-500px w-100"
              // imgOverlay="40"
              className="cover cover-full-top"
              // type="video-popup"
              videoSrc={item.attributes && item.attributes.url}
              type={{ name: "core-embed/youtube" }}
              audio={false}
            />
          </div>
        )
      case "core/cover":
        if (i > 0) {
          return (
            <div key={`cover-${i}`}>
              <CoverFeatured
                img={item.attributes.url || data.featured.childImageSharp.fluid}
                title={
                  item.innerBlocks[0] && item.innerBlocks[0].attributes.content
                }
                text={
                  item.innerBlocks[1] &&
                  item.innerBlocks[1].attributes.content.split("<br>").join(" ")
                }
                btnText={"Kirim Pesan"}
                btnLink={`/investors${item.innerBlocks[2].innerBlocks[0].attributes.url}`}
              />
            </div>
          )
        } else {
          return <div key={`cover-${i}`}></div>
        }
      case "core/shortcode":
        return (
          <ComponentCustom
            key={`component-custom-${i}`}
            data={item}
            reportsData={annualReports}
            stockPrice={
              stock.bsde !== 0 && stock.duti !== 0 && stock.a26 !== 0 && stock
            }
            lang="ID"
          />
        )
      case "core/paragraph":
        return (
          <div key={`headingbasic-${i}`} className="py-main">
            <div className="container container-md">
              <HeadingBasic text={[item.attributes.content]} />
            </div>
          </div>
        )
      default:
        return <div key={`default-${i}`}>{item.name}</div>
    }
  }

  return (
    <Layout location={location} currentLocation={location.uri} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={coverTitle}
        img={
          cover ? cover.attributes.url : data.imgCoverMain.childImageSharp.fluid
        }
        alt="General Information"
        className="cover-md"
      >
        <Breadcrumbs path={location.uri} className="breadcrumb-light" />
      </CoverMain>

      {wordPressID.map((item, i) => {
        return <Blocks item={item} i={i} />
      })}
    </Layout>
  )
}

export default InvestorsPage
