import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const TestingPage = ({ location }) => {

  return (
    <Layout location={location} altNav>
      <Seo title="Revive testing" />

      <h1>Revive Page</h1>
      {/* <!-- Revive Adserver iFrame Tag - Generated with Revive Adserver v5.5.2 --> */}
      <iframe id='a0a66fda' name='a0a66fda' src='https://rv.sinarmasland.com/www/delivery/afr.php?zoneid=23&amp;cb=123' frameborder='0' scrolling='no' width='510' height='510' allow='autoplay'><a href='https://rv.sinarmasland.com/www/delivery/ck.php?n=a1aa1e64&amp;cb=123' target='_blank'><img src='https://rv.sinarmasland.com/www/delivery/avw.php?zoneid=23&amp;cb=123&amp;n=a1aa1e64' border='0' alt='' /></a></iframe>


      <h2>Staging Revive Page</h2>
      {/* <!-- Revive Adserver iFrame Tag - Generated with Revive Adserver v5.5.2 --> */}
      <iframe id='a0b36366' name='a0b36366' src='https://staging.sinarmasland.com/revive/www/delivery/afr.php?zoneid=6&amp;cb=234' frameborder='0' scrolling='no' width='510' height='510' allow='autoplay'><a href='https://staging.sinarmasland.com/revive/www/delivery/ck.php?n=ab4eb32a&amp;cb=234' target='_blank'><img src='https://staging.sinarmasland.com/revive/www/delivery/avw.php?zoneid=6&amp;cb=234&amp;n=ab4eb32a' border='0' alt='' /></a></iframe>

    </Layout>
  )
}

export default TestingPage
