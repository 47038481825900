
/**
 * Detects if a given string is a valid Instagram URL
 * @param {string} url - The URL string to check
 * @returns {boolean} - Whether the string is a valid Instagram URL
 */
export function isInstagramUrl(url) {
    // Trim the URL and convert to lowercase for consistent checking
    const cleanUrl = url.trim().toLowerCase();

    // Regular expression to match Instagram URLs
    // Supports:
    // - Profile URLs: instagram.com/username
    // - Post URLs: instagram.com/p/postId/
    // - Reels URLs: instagram.com/reel/reelId/
    const instagramUrlPattern = /^(https?:\/\/)?(www\.)?instagram\.com\/(p\/[a-zA-Z0-9_-]+|reel\/[a-zA-Z0-9_-]+|[a-zA-Z0-9_\.]+)\/?$/;

    // Check if the URL matches the Instagram pattern
    return instagramUrlPattern.test(cleanUrl);
}
