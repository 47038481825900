export const objectToQueryParams = (obj) => {
    if (!obj) return ""

    const urlParams = Object.keys(obj).map(key => {
        const value = obj[key];
        if (value === null || value === undefined) {
            return encodeURIComponent(key);
        }
        if (Array.isArray(value)) {
            return value.map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`).join('&');
        }
        if (typeof value === 'object') {
            return objectToQueryParams(value).split('&').map(param => `${encodeURIComponent(key)}[${param}`).join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }).join('&');
    return `${urlParams}`
}

export const queryParamsToObject = (queryString) => {
    if (queryString.startsWith('?')) {
        queryString = queryString.slice(1);
    }

    const pairs = queryString.split('&');
    const result = {};

    pairs.forEach(pair => {
        const [key, value] = pair.split('=');
        if (!key) return;

        const decodedKey = decodeURIComponent(key);
        const decodedValue = value ? decodeURIComponent(value) : null;

        addToObject(result, decodedKey, decodedValue);
    });

    return result;
}

function addToObject(obj, key, value) {
    const keys = key.replace(/\]/g, '').split('[');
    let currentObj = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        const currentKey = keys[i];
        if (!currentObj[currentKey]) {
            currentObj[currentKey] = keys[i + 1] === '' ? [] : {};
        }
        currentObj = currentObj[currentKey];
    }

    const lastKey = keys[keys.length - 1];
    if (lastKey === '') {
        currentObj.push(value);
    } else if (currentObj[lastKey]) {
        if (!Array.isArray(currentObj[lastKey])) {
            currentObj[lastKey] = [currentObj[lastKey]];
        }
        currentObj[lastKey].push(value);
    } else {
        currentObj[lastKey] = value;
    }
}

export function removeEmptyValues(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(removeEmptyValues).filter(val => val !== undefined);
    }
  
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, removeEmptyValues(value)])
        .filter(([_, value]) => {
          if (value === null || value === undefined || value === '') {
            return false;
          }
          if (typeof value === 'object' && Object.keys(value).length === 0) {
            return false;
          }
          return true;
        })
    );
  }