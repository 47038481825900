import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ReviveAd from "../components/reviveAd"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

/* const ReviveAdProd = () => {
  return (
    <>
      <ins data-revive-zoneid="23" data-revive-id="4d3dce4db16ff385dd1c5dd5ade84a88"></ins>
      <script async src="//rv.sinarmasland.com/www/delivery/asyncjs.php"></script>
    </>
  )
} */

const ReviveAdStaging = () => {
  return (
    <div className="revive-container">
      <ins data-revive-zoneid="4" data-revive-id="47af5367fea0069bf2fc4b4a92c0418c"></ins>
      <script async src="//staging.sinarmasland.com/revive/www/delivery/asyncjs.php"></script>
    </div>
  )
}

const TestingPage = ({ location }) => {

  return (
    <Layout location={location} altNav>
      <Seo title="Revive testing" />

      {/* <h1>Revive Page</h1>
      <ReviveAdProd /> */}

      <h2>Staging Revive Page</h2>
      <ReviveAdStaging />

      <h2>Staging Revive React Page</h2>
      <ReviveAd 
        zoneId="4" 
        reviveId="47af5367fea0069bf2fc4b4a92c0418c" 
      />
    </Layout>
  )
}

export default TestingPage
