import React, { useEffect, useRef } from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const ReviveAd = ({ zoneId = '5', stagingUrl = 'staging.sinarmasland.com' }) => {
  const adContainerRef = useRef(null);

  // Function to generate 6 random numbers
  const generateSixRandomNumbers = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  useEffect(() => {
    if (!adContainerRef.current) return;

    const loadAd = async () => {
      try {
        // Generate random number
        const randomNumber = Math.floor(Math.random() * 99999999999);
        
        // Determine protocol
        const protocol = window.location.protocol;
        const baseUrl = `${protocol}//${stagingUrl}/revive/www/delivery/ajs.php`;
        
        // Build query parameters
        const params = new URLSearchParams({
          zoneid: zoneId,
          cb: randomNumber,
          charset: document.characterSet || document.charset || '',
          loc: window.location.href,
          referer: document.referrer || ''
        });

        if (document.MAX_used && document.MAX_used !== ',') {
          params.append('exclude', document.MAX_used);
        }

        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `${baseUrl}?${params.toString()}`;

        // Add the script to the container
        adContainerRef.current.innerHTML = ''; // Clear previous content
        adContainerRef.current.appendChild(script);

        // Update MAX_used
        if (!document.MAX_used) document.MAX_used = ',';
        document.MAX_used += `${zoneId},`;

      } catch (error) {
        console.error('Error loading Revive ad:', error);
      }
    };

    loadAd();

    // Cleanup function
    return () => {
      if (adContainerRef.current) {
        adContainerRef.current.innerHTML = '';
      }
    };
  }, [zoneId, stagingUrl]);

  // Generate 6-digit random number for fallback URLs
  const sixDigitRandom = generateSixRandomNumbers();
  
  // Fallback for users with JavaScript disabled
  const fallbackUrl = `https://${stagingUrl}/revive/www/delivery/ck.php?n=a26b78c0&cb=${sixDigitRandom}`;
  const fallbackImageUrl = `https://${stagingUrl}/revive/www/delivery/avw.php?zoneid=${zoneId}&cb=${sixDigitRandom}&n=a26b78c0`;

  return (
    <div className="revive-ad-container" style={{
      width: "100%",
      minHeight: '90px', /* Adjust based on your ad size */
      margin: '20px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div ref={adContainerRef}></div>
      <noscript>
        <a href={fallbackUrl} target="_blank" rel="noopener noreferrer">
          <img src={fallbackImageUrl} border="0" alt="" />
        </a>
      </noscript>
    </div>
  );
};

const TestingPage = ({ location }) => {

  return (
    <Layout location={location} altNav>
      <Seo title="Register" />

      <h1>Revive Page</h1>
      {console.log('before ad')}
      <div className="ad-placement">
        <ReviveAd />
      </div>
      {console.log('after ad')}
    </Layout>
  )
}

export default TestingPage
