import React, { useEffect } from 'react';

const ReviveAd = ({ zoneId, reviveId, src = '//staging.sinarmasland.com/revive/www/delivery/asyncjs.php' }) => {
  useEffect(() => {
    // Create the ins element
    const ins = document.createElement('ins');
    ins.setAttribute('data-revive-zoneid', zoneId);
    ins.setAttribute('data-revive-id', reviveId);
    
    // Create the script element
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    
    // Get reference to the container
    const adContainer = document.getElementById('revive-ad-container');
    
    // Append elements
    if (adContainer) {
      adContainer.appendChild(ins);
      adContainer.appendChild(script);
    }
    
    // Cleanup function
    return () => {
      if (adContainer) {
        adContainer.innerHTML = '';
      }
    };
  }, [zoneId, reviveId]); // Re-run effect if zoneId or reviveId changes

  return <div id="revive-ad-container" className="revive-container"></div>;
};

export default ReviveAd;
