import React, { useEffect, useState, useContext } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { useLazyQuery, useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { useMediaPredicate } from "react-media-hook"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/langContext"

import { CardSearchPrice } from "../components/card/card"
import { Section, Container } from "../components/grid/grid"
import { FormSMLHome } from "../components/forms-sml/forms-sml"

import { queryParamsToObject, objectToQueryParams, removeEmptyValues } from "../utils/obj-to-param"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const FIND_PROPERTY = gql`
  query Finder(
    $facilityId: [ID]
    $locationId: [ID]
    $typeId: [ID]
  ) {
    projects(
      first: 1000
      where: {
        facilityIn: $facilityId
        locationIn: $locationId
        propertyTypeIn: $typeId
      }
    ) {
      edges {
        cursor
        node {
          databaseId
          title
          slug
          excerpt
          date
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            caption
            sourceUrl
          }
          priceProduct {
            price
          }
          locations {
            nodes {
              name
              slug
            }
          }
          facilities {
            nodes {
              name
              slug
              facilityId
              facilityIcon {
                icon {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

const QUERY_STATIC_FACILITIES = graphql`
  query GetFacilities {
    wordPress {
      facilities(first: 100) {
        nodes {
          name
          slug
          facilityId
          projects {
            nodes {
              propertyTypes {
                nodes {
                  slug
                  name
                  projects {
                    nodes {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
      locations(first: 100) {
        nodes {
          locationId
          slug
          name
          projects {
            nodes {
              title
              priceProduct {
                price
              }
            }
          }
        }
      }
      propertyTypes(where: {language: EN}, first: 100) {
        nodes {
          name
          slug
          propertyTypeId
          children {
            nodes {
              slug
              name
              projects(first: 1000) {
                nodes {
                  title
                  slug
                  priceProduct {
                    price
                  }
                }
              }
            }
          }
        }
      }

      categories {
        nodes {
          name
          posts(first: 1000) {
            nodes {
              slug
            }
          }
        }
      }
      projects(first: 1000) {
        nodes {
          id
          projectTags {
            edges {
              node {
                name
              }
            }
          }
          title
          slug
          excerpt
          priceProduct {
            price
          }
          locations {
            nodes {
              name
              slug
            }
          }
          propertyTypes(first: 100) {
            nodes {
              name
              slug
            }
          }
        }
      }
      page(id: "career", idType: URI) {
        title
        slug
        excerpt
      }
    }
  }
`

const GET_PROPERTY_TYPES = gql`
  query GetPropertyTypes {
    propertyTypes(where: {language: EN}, first: 100) {
      nodes {
        name
        slug
        propertyTypeId
      }
    }
  }
`

const changeParentSlug = (slug) => {
  switch(slug) {
    case "kavling-doubledream-property":
      slug = "kavling";
      break;
    case "residential-doubledream-property":
      slug = "residential";
      break;
  }
  return slug
}

const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
  if (hasNextPage || hasPreviousPage) {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={!hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={!hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  } else {
    return <div className="pagination"></div>
  }
}

export const SearchPriceResult = ({ location }) => {
  const offset = 0 
  const perPage = 12
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const [total, setTotal] = useState(null)
  const [totalRelated, setTotalRelated] = useState(null)
  const [lastLocations, setLastLocations] = useState(null)
  const { lang } = useContext(LangContext)
  const [formState, setFormState] = useState({
    location: null,
    propertyTypes: null,
    projects: null,
    fromPrice: null,
    toPrice: null,
    checkBoxValues: [],
  })
  const [pagination, setPagination] = useState({
    offset,
    perPage
  })
  const [filteredProjects, setFilteredProjects] = useState([])
  const [relatedProjects, setRelatedProjects] = useState([])
  const [isSubmitFilter, setSubmitFilter] = useState(false)
  const [filterState, setFilterState] = useState(location.state ?? queryParamsToObject(location.search))

  const data = useStaticQuery(QUERY_STATIC_FACILITIES)

  const { data: dataPropertyTypes } = useQuery(GET_PROPERTY_TYPES)
  //prettier-ignore
  const [ getProperty, { data: properties, loading: loadingProperties }] = useLazyQuery(FIND_PROPERTY)

  const listFacilities = data.wordPress.facilities.nodes
  const listLocations = data.wordPress.locations.nodes
  const listPropertyTypes = dataPropertyTypes?.propertyTypes?.nodes || data.wordPress.propertyTypes.nodes

  const propertyFinderResult = properties && properties.projects.edges

  const defaultPropertyTypesId = listPropertyTypes.map(item => item.propertyTypeId)
  const listStaticPropertyTypes = listPropertyTypes.filter(item =>
    item.slug !== "doubledream-faq" && 
    item.slug !== "faq" && 
    item.slug !== "infiniteliving-faq" && 
    item.slug !== "properti" && 
    item.slug !== "doubledream-property" && 
    item.slug !== "smartmove-faq" && 
    item.slug !== "smartmove-property" 
  ).map((item)=>({ label: item.name, value: item.slug }))
  const listStaticLocations = [
    { label: "All Location", value:"" },
    ...( listLocations.map(item => ({ label: item.name, value: item.slug })) )
  ]
  let listStaticProjects = properties ?
    properties.projects.edges.map(item => ({ label: item.node.title, value: item.node.slug })) : []

  // min. price data
  const minPriceData = [
    {label: "Min. Price (Rp)", value: 0},
    {label: "Rp 500jt", value: 500000000},
    {label: "Rp 600jt", value: 600000000},
    {label: "Rp 700jt", value: 700000000},
    {label: "Rp 800jt", value: 800000000},
    {label: "Rp 900jt", value: 900000000},
    {label: "Rp 1M", value: 1000000000},
    {label: "Rp 2M", value: 2000000000},
    {label: "Rp 3M", value: 3000000000},
    {label: "Rp 4M", value: 4000000000},
    {label: "Rp 5M", value: 5000000000},
    {label: "Rp 6M", value: 6000000000},
    {label: "Rp 7M", value: 7000000000},
    {label: "Rp 8M", value: 8000000000},
    {label: "Rp 9M", value: 9000000000},
    {label: "Rp 10M", value: 10000000000},
    {label: "Rp 11M", value: 11000000000},
    {label: "Rp 12M", value: 12000000000},
    {label: "Rp 13M", value: 13000000000},
    {label: "Rp 14M", value: 14000000000},
    {label: "Rp 15M", value: 15000000000},
    {label: "Rp 16M", value: 16000000000},
    {label: "Rp 17M", value: 17000000000},
    {label: "Rp 18M", value: 18000000000},
    {label: "Rp 19M", value: 19000000000},
    {label: "Rp 20M", value: 20000000000},
  ]
  
  // max. price data
  const maxPriceData = [
    {label: "Max. Price (Rp)", value: 0},
    {label: "Rp 500jt", value: 500000000},
    {label: "Rp 600jt", value: 600000000},
    {label: "Rp 700jt", value: 700000000},
    {label: "Rp 800jt", value: 800000000},
    {label: "Rp 900jt", value: 900000000},
    {label: "Rp 1M", value: 1000000000},
    {label: "Rp 2M", value: 2000000000},
    {label: "Rp 3M", value: 3000000000},
    {label: "Rp 4M", value: 4000000000},
    {label: "Rp 5M", value: 5000000000},
    {label: "Rp 6M", value: 6000000000},
    {label: "Rp 7M", value: 7000000000},
    {label: "Rp 8M", value: 8000000000},
    {label: "Rp 9M", value: 9000000000},
    {label: "Rp 10M", value: 10000000000},
    {label: "Rp 11M", value: 11000000000},
    {label: "Rp 12M", value: 12000000000},
    {label: "Rp 13M", value: 13000000000},
    {label: "Rp 14M", value: 14000000000},
    {label: "Rp 15M", value: 15000000000},
    {label: "Rp 16M", value: 16000000000},
    {label: "Rp 17M", value: 17000000000},
    {label: "Rp 18M", value: 18000000000},
    {label: "Rp 19M", value: 19000000000},
    {label: "Rp 20M", value: 20000000000},
  ]

  //const maxPriceDefault = Math.max.apply(Math, maxPriceList.map(item => {item.value}))
    const maxPriceDefault = maxPriceData[maxPriceData.length - 1].value

  const compareByDate = (a, b) => {
    const dateA = a.node.date
    const dateB = b.node.date

    let comparison
    if (dateA < dateB) {
      comparison = 1
    } else if (dateA > dateB) {
      comparison = -1
    }

    return comparison
  }

  useEffect(() => {
    let listOfState = {}

    const propertyTypeId = filterState.propertyTypes ?
      listPropertyTypes.find(x => x.name === filterState.propertyTypes?.label).propertyTypeId : null

    const locations = filterState.values?.location?.label ?
      listLocations.filter(item => item.name === filterState?.values?.location?.label).map(item => item.locationId) : []

    const facilities = filterState.values?.residenceFacilities?.length > 0 ? 
      listFacilities.filter(item => filterState.values.residenceFacilities.includes(item.slug)).map(item => item.facilityId) 
      : []

    setLastLocations(locations)

    if(filterState !== null) {
      getProperty({
        variables: {
          facilityId: facilities.length > 0 ? facilities : null,
          locationId: locations.length > 0 ? locations : null,
          typeId: propertyTypeId ? [propertyTypeId] : defaultPropertyTypesId,
        },
      })
      if(filterState.propertyType){
        listOfState = {...listOfState, propertyTypes: filterState.propertyType}
      }
      if(filterState.values){
        if(filterState.values.location){
          listOfState = {...listOfState, location: filterState.values.location}
        }
        if(filterState.values.priceRanges){
          listOfState = {...listOfState,
            fromPrice: minPriceData.find((item) => item.value === Number(filterState.values.priceRanges.fromPrices)), 
            toPrice: maxPriceData.find((item) => item.value === Number(filterState.values.priceRanges.toPrices)), 
          }
        }
        if(filterState.values.residenceFacilities?.length > 0){
          listOfState = {...listOfState, checkBoxValues: filterState.values.residenceFacilities}
          listOfState.checkBoxValues.map(item => {
            if(item){
              document.getElementById(item).checked = true
            }else{
              document.getElementById(item).checked = false
            }
          })
        }else{
          listOfState = {...listOfState, checkBoxValues: []}
        }
        setFormState(listOfState)
      }
    }
  }, [filterState])

    useEffect(() => {
        // start filter by priceProduct
        if(properties != undefined){
            if(filterState?.values?.priceRanges?.fromPrices !== undefined
                && filterState?.values?.priceRanges?.toPrices !== undefined){
                const projects = []
                for(const prop of propertyFinderResult) {
                    const priceProduct = prop?.node?.priceProduct?.price 
                    if(priceProduct !== null) {
                        if(priceProduct >= filterState?.values?.priceRanges?.fromPrices 
                            && priceProduct <= filterState?.values?.priceRanges?.toPrices) {
                            projects.push(prop)
                        }
                    }
                }
                setFilteredProjects(projects);
                setTotal(projects.length)

                if (projects.length === 0){
                  if (lastLocations.length > 0){
                    setRelatedProjects(propertyFinderResult)
                    setTotalRelated(propertyFinderResult.length)
                  }else{
                    getProperty({
                      variables: {
                        facilityId: null,
                        locationId: null,
                        typeId: defaultPropertyTypesId,
                      },
                    })
                    
                    const arrayList = []
                    for(const prop of propertyFinderResult.sort(compareByDate)) {
                      arrayList.push(prop)
                    }
                    
                    setRelatedProjects(arrayList.splice(0, 15))
                    setTotalRelated(arrayList.splice(0, 15).length)
                  }
                }
            } else {
                setFilteredProjects(propertyFinderResult)
                setTotal(propertyFinderResult.length)
            }
        }
    }, [properties, isSubmitFilter])

  // search form
  useEffect(() => {
    if (formState.location && formState.propertyTypes && formState.projects) {
      navigate(`/development/${formState.propertyTypes.value}/${formState.projects.value}`)
    }

    const facilitiesEf = formState.checkBoxValues.length > 0 ? 
      listFacilities.filter(item => formState.checkBoxValues.includes(item.slug)).map(item => item.facilityId) 
      : []

    const propertyTypeIdEf = formState.propertyTypes &&
      listPropertyTypes.find(x => x.slug === formState.propertyTypes.value).propertyTypeId

    const locationsEf = formState.location ?
      listLocations.filter(item => item.name === formState.location.label).map(item => item.locationId) : []

    if (locationsEf.length > 0 && propertyTypeIdEf) {
      getProperty({
        variables: {
          locationId: locationsEf.length > 0 ? locationsEf : null,
          typeId: [propertyTypeIdEf],
          facilityId: facilitiesEf.length > 0 ? facilitiesEf : null,
        },
      })
    }
  }, [formState])

  useEffect(() => {
    if(resizeScreen){
      document.querySelector(".search-result").style.paddingTop = "0px"
      document.querySelector(".search-result .mw-xl").style.padding = "0px"
      document.querySelector("section.tab-content-section").style.backgroundColor = "white"
      document.querySelector("section.tab-content-section").style.marginBottom = "20px"
    }else{
      document.querySelector(".search-result").style.paddingTop = "5rem"
      document.querySelector(".search-result .mw-xl").style.padding = "0px 20px"
      document.querySelector("section.tab-content-section").style.backgroundColor = "transparent"
      document.querySelector("section.tab-content-section").style.marginBottom = "0px"
    }
  }, [])

  useEffect(() => {
    setFilterState(location.state ?? queryParamsToObject(location.search))
  }, [location])

  const locationHandler = e => {
    setFormState({ ...formState, location: e })
  }

  const propertyTypeHandler = e => {
    setFormState({ ...formState, propertyTypes: e })
  }

  const projectsHandler = e => {
    setFormState({ ...formState, projects: e })
  }

  const fromPriceHandler = e => {
    setFormState({ ...formState, fromPrice: e })
  }

  const toPriceHandler = e => {
    setFormState({ ...formState, toPrice: e })
  }

  const checkBoxValuesHandler = e => {
    let checkBoxValuesTemp = formState.checkBoxValues
    e.checked && checkBoxValuesTemp.push(e.value)
    //prettier-ignore
    !e.checked && checkBoxValuesTemp.splice(checkBoxValuesTemp.indexOf(e.value), 1)
    setFormState({ ...formState, checkBoxValues: checkBoxValuesTemp })
  }
  // end search form

  const hasPreviousPage = () => {
    if (properties) {
        if(pagination.offset > offset) {
            return true
        }
    } else {
      return false
    }
  }

  const hasNextPage = () => {
    if (properties) {
        if(totalRelated && totalRelated !== 0){
          if(pagination.perPage < totalRelated) {
            return true
          }
        }else{
          if(pagination.perPage < total) {
            return true
          }
        }
    } else {
      return false
    }
  }

  const nextPage = () => {
    if (properties)  {
        setPagination({
            ...pagination,
            offset: pagination.perPage, 
            perPage: pagination.perPage + perPage 
        })
    }
  }

  const previousPage = () => {
    if (properties) {
        setPagination({ 
            ...pagination, 
            offset: pagination.offset - perPage,
            perPage: pagination.perPage - perPage 
        })
    }
  }

  // search form
  const propertyFinderSubmit = e => {
    e.preventDefault()
    if (
      formState.location &&
      formState.propertyTypes &&
      formState.projects
    ) {
        navigate(
            `/development/${formState.propertyTypes.value}/${formState.projects.value}`
        )
    }

    if(formState?.location !== null || formState?.projects !== null
    || formState?.propertyTypes !== null || formState?.checkBoxValues?.length > 0 
    || formState?.fromPrice !== null || formState?.toPrice !== null) {

      const thisPriceRanges = {
        fromPrices: formState?.fromPrice?.value < formState?.toPrice?.value ? formState?.fromPrice?.value : 0,
        toPrices: formState?.toPrice?.value ?? maxPriceDefault,
      }

      const thisLocations = formState.location ?
        listLocations.filter(item => item.name === formState.location.label).map(item => item.locationId) : []

      /* const thispropertyTypesId = formState.propertyTypes ?
        listPropertyTypes.find(x => x.name === formState.propertyTypes?.label)?.propertyTypeId : null */

      /* const thisFacilities = formState.checkBoxValues.length > 0 ? 
        listFacilities.filter(item => formState.checkBoxValues.includes(item.slug)).map(item => item.facilityId) 
        : [] */

      setFilterState((prev) => ({
        ...prev,
        values:{
          ...prev.values,
          priceRanges: thisPriceRanges
        }
      }))

      setLastLocations(thisLocations)

      // reset pagination
      setPagination({...pagination, offset, perPage})
      setSubmitFilter(!isSubmitFilter)

      /* getProperty({
        variables: {
          locationId: thisLocations.length > 0 ? thisLocations : null,
          typeId: thispropertyTypesId ? [thispropertyTypesId] : defaultPropertyTypesId,
          facilityId: thisFacilities.length > 0 ? thisFacilities : null,
        },
      }) */

      const objFilter = {
        propertyType: formState.propertyTypes,
        values: {
          location: formState.location,
          residenceFacilities: formState.checkBoxValues,
          priceRanges: thisPriceRanges,
        },
      }

      navigate(
        `/search-price-result?${objectToQueryParams(removeEmptyValues(objFilter))}`, 
        { state: objFilter }
      )
    }
  }
  // end search form

  return (
    <Layout location={location}>
      <SEO title="Search Result" />

      <Section className="search-result bg-light" style={{paddingTop: "0px"}}>
        <Container className="mw-xl">
          <section className="tab-content-section">
            <div className="container mw-lg px-lg-up-0">
              <div className="tab-content-wrapper">
                <FormSMLHome
                  locations={listStaticLocations}
                  propertyTypes={listStaticPropertyTypes}
                  projects={listStaticProjects}
                  fromPrice={minPriceData}
                  toPrice={maxPriceData}
                  valueLocation={formState.location ? formState.location.value : " "}
                  valuePropertyType={formState.propertyTypes ? formState.propertyTypes.value : ""}
                  valueMinPrice={formState.fromPrice?.value ? Number(formState.fromPrice.value) : ""}
                  valueMaxPrice={formState.toPrice?.value ? Number(formState.toPrice.value) : ""}
                  onCheckBoxChange={checkBoxValuesHandler}
                  onLocationChange={locationHandler}
                  onPropertyTypesChange={propertyTypeHandler}
                  onProjectsChange={projectsHandler}
                  onFromPriceChange={fromPriceHandler}
                  onToPriceChange={toPriceHandler}
                  onSubmit={propertyFinderSubmit}
                  isLoading={loadingProperties}
                />
              </div> 
            </div>
          </section>
        </Container>
        <Container className="mw-xl">
          {/* <HeadingBasic h2="Search" /> */}
          {total && total !== 0 ? (
            <h5 className="mb-3 text-muted">{total} results found</h5>
          ) : null}
          {filteredProjects && (
            <div className="row col-md-12 m-0 p-0">
              {filteredProjects && filteredProjects.slice((pagination.offset), pagination.perPage).map((item, i) => {
                  let listFacilitiesProperties = []
                  let thisSubPropertyType = ""

                  // eslint-disable-next-line
                  item?.node?.facilities?.nodes.forEach(listValue => {
                    if(filterState?.values?.residenceFacilities && filterState?.values?.residenceFacilities.length > 0){
                      // eslint-disable-next-line
                      filterState?.values?.residenceFacilities.forEach(resultValue => {
                        if(resultValue === listValue.slug){
                          listFacilitiesProperties.push({
                            image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                            details: listValue.name,
                          })
                        }
                      })
                    }else if(formState?.checkBoxValues && formState?.checkBoxValues.length > 0 && formState?.checkBoxValues !== " "){
                      let checkedList = formState?.checkBoxValues
                      // eslint-disable-next-line
                      checkedList.forEach(item => {
                        if(item && item !== " " && item === listValue.slug){
                          listFacilitiesProperties.push({
                            image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                            details: listValue.name,
                          })
                        }
                      })
                    }else{
                      listFacilitiesProperties.push({
                        image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                        details: listValue.name,
                      })
                    }
                  })

                  if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "doubledream property") {
                    listPropertyTypes.forEach(thisItem => {
                      if(thisItem.slug === "doubledream-property"){
                        thisItem.children.nodes.forEach(thisChildren => {
                          thisChildren.projects.nodes.forEach(thisProject => {
                            if(thisProject.slug === item.node.slug){
                              thisSubPropertyType = changeParentSlug(thisChildren.slug)
                            }
                          })
                        })
                      }
                    })
                  }

                  return (
                    <div className="col-md-4" key={`${item?.node?.slug}-${i}`}>
                      <CardSearchPrice
                        forwardKey={`post-${i}`}
                        title={
                          lang === "EN"
                            ? item?.node?.title
                            : item?.node?.translation?.title || item?.node?.title
                        }
                        label={
                          item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                          "doubledream property"
                          ? "Double Dream"
                          : item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                            "infinite living"
                          ? "Infinite Living"
                          : "Development"
                        }
                        labelClassName="mb-2 font-size-sm"
                        locations={
                          item?.node?.locations?.nodes[0]?.name 
                            ? item?.node?.locations?.nodes[0]?.name
                            : "Location"
                        }
                        // locationClassName={}
                        priceProject={item.node.priceProduct.price}
                        // priceProjectClassName={}
                        facilities={listFacilitiesProperties.slice(0,2)}
                        facilitiesClassName="flex-nowrap"
                        img={
                          item?.node?.featuredImage?.sourceUrl
                        }
                        titleClassName="h5 text-truncate-twoline"
                        link={
                          item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "doubledream property"
                          ? `/doubledream/${thisSubPropertyType}/${item?.node?.locations?.nodes[0]?.slug}/${item?.node?.slug}/` 
                          : item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "infinite living"
                          ? `/development/${item?.node?.propertyTypes?.nodes[1]?.slug}/${item?.node?.slug}/`
                          : `/development/${item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase()}/${item?.node?.slug}/`
                        }
                        className="card-price-search mb-3"
                      >
                      </CardSearchPrice>
                    </div>
                  )
                })}
            </div>
          )}
          {!loadingProperties &&
            properties !== undefined &&
            filteredProjects.length === 0 && (
            <div className="row col-md-12 m-0 pb-5 pl-0 pr-0 justify-content-center">
              {filteredProjects.length} {lang === "EN" ? "Results Not Found" : "Hasil Tidak Ditemukan"}
            </div>
          )}
          {totalRelated && totalRelated !== 0 ? (
            <h5 className="mb-3 text-muted">{totalRelated} {lang === "EN" ? `results for "related project"` : `hasil untuk "proyek terkait"`}</h5>
          ) : null}
          {relatedProjects && (
            <div className="row col-md-12 m-0 p-0">
              {relatedProjects && relatedProjects.slice((pagination.offset), pagination.perPage).map((item, i) => {
                let listFacilitiesProperties = []
                let thisSubPropertyType = ""

                // eslint-disable-next-line
                item?.node?.facilities?.nodes.forEach(listValue => {
                  if(filterState?.values?.residenceFacilities && filterState?.values?.residenceFacilities.length > 0){
                    // eslint-disable-next-line
                    filterState?.values?.residenceFacilities.forEach(resultValue => {
                      if(resultValue === listValue.slug){
                        listFacilitiesProperties.push({
                          image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                          details: listValue.name,
                        })
                      }
                    })
                  }else if(formState?.checkBoxValues && formState?.checkBoxValues.length > 0 && formState?.checkBoxValues !== " "){
                    let checkedList = formState?.checkBoxValues
                    // eslint-disable-next-line
                    checkedList.forEach(item => {
                      if(item && item !== " " && item === listValue.slug){
                        listFacilitiesProperties.push({
                          image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                          details: listValue.name,
                        })
                      }
                    })
                  }else{
                    listFacilitiesProperties.push({
                      image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                      details: listValue.name,
                    })
                  }
                })

                if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "doubledream property") {
                  listPropertyTypes.forEach(thisItem => {
                    if(thisItem.slug === "doubledream-property"){
                      thisItem.children.nodes.forEach(thisChildren => {
                        thisChildren.projects.nodes.forEach(thisProject => {
                          if(thisProject.slug === item.node.slug){
                            thisSubPropertyType = changeParentSlug(thisChildren.slug)
                          }
                        })
                      })
                    }
                  })
                }

                return (
                  <div className="col-md-4" key={`${item?.node?.slug}-${i}`}>
                    <CardSearchPrice
                      forwardKey={`post-${i}`}
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      label={
                        item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                          "doubledream property"
                        ? "Double Dream"
                        : "Development"
                      }
                      labelClassName="mb-2 font-size-sm"
                      locations={
                        item?.node?.locations?.nodes[0]?.name 
                          ? item?.node?.locations?.nodes[0]?.name
                          : "Location"
                      }
                      // locationClassName={}
                      priceProject={item.node.priceProduct.price}
                      // priceProjectClassName={}
                      facilities={listFacilitiesProperties.slice(0,2)}
                      facilitiesClassName="flex-nowrap"
                      img={
                        item?.node?.featuredImage?.sourceUrl
                      }
                      titleClassName="h5 text-truncate-twoline"
                      link={
                        item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                        "doubledream property"
                        ? `/doubledream/${thisSubPropertyType}/${item?.node?.locations?.nodes[0]?.slug}/${item?.node?.slug}/`
                        : `/development/${item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase()}/${item?.node?.slug}/`
                      }
                      className="card-price-search mb-3"
                    >
                    </CardSearchPrice>
                  </div>
                )
              })}
            </div>
          )}
          <Pagination
            hasPreviousPage={hasPreviousPage()}
            hasNextPage={hasNextPage()}
            prevPage={() => previousPage()}
            nextPage={() => nextPage()}
          />
          {(loadingProperties) && (
            <div className="text-center loader-lg">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default SearchPriceResult
