import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import moment from "moment"
import Select from "react-select"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/grid/grid"
import { getDate } from "../utils/get-date"
import { decodeHTMLEntities } from "../utils/decode-html"
import { Card } from "../components/card/card"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const removeDuplicateCategories = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const IndeksPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        categories(first: 100, where: {exclude: 1}) {
          nodes {
            slug
            name
            posts(first: 1000) {
              nodes {
                slug
                title
                excerpt
                dateGmt
                featuredImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `)

  const now = moment().format('YYYY-MM-DD')
  const [formValues, setFormValues] = useState({
    category: "all",
    dateTime: now
  })

  const offset = 0
  const limit = 12
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(limit)
  const [filteredPosts, setFilteredPosts] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [nothingPostMsg, setNothingPostMsg] = useState("")
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [masterPost, setMasterPost] = useState([])
  const [categorySelectedList, setCategorySelectedList] = useState([])
  const [categorySelected, setCategorySelected] = useState([])

  const allCategoryLabel = { value: "all", label: "Select news category" }

  useEffect(() => {
    if (staticData?.wordPress?.categories) {
      setIsLoadingData(false)

      const categoriesList = staticData?.wordPress?.categories

      categoriesList?.nodes?.forEach(category => {
        setCategorySelectedList((prevState)=>[...prevState, {value: category?.slug, label: category?.name}])

        category?.posts?.nodes?.forEach(item => {
          setMasterPost((prevState)=>[...prevState, {...item, categoryName: category?.name, categorySlug: category?.slug}])
        })
      })
    }
  }, [staticData])

  useEffect(() => {
    if (categorySelectedList.length > 0) {
      setCategorySelected([
        allCategoryLabel, 
        ...removeDuplicateCategories(categorySelectedList, 'value').map((item) => ({...item, label: decodeHTMLEntities(item.label)}))
      ])
    }
  }, [categorySelectedList])

  const handleDateTime = (e) => {
    setFormValues({...formValues, dateTime: e.target.value})
  }

  const handleCategoryChange = (e) => { 
    setSelectedCategory(e)
    setFormValues({...formValues, category: e.value})
    console.log('handleCategoryChange', e, e.value)
  }

  const handleLoadMore = () => {
    const nextPage = page + 1
    const nextPerPage = nextPage * limit
    console.log({nextPage, nextPerPage})
    setPage(nextPage)
    setPerPage(nextPerPage)
  }

  const FormIndeks = () => {
    return (
      <div className="pb-main">
        <Container>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <label>{"Category"}</label>
                <Select
                  name="category"
                  className="form-select w-100"
                  classNamePrefix="select"
                  options={categorySelected}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  placeholder={"Select news category"}
                />
              </div>
              <div className="col-md-6">
                <label>{"Date"}</label>
                <Field
                  className="form-control"
                  name="dateTime"
                  type="date"
                  onChange={e => handleDateTime(e)}
                />
              </div>
            </div>
            <button
              className="btn btn-secondary btn-block mt-4"
              disabled={isLoadingData}
            >
              {isLoadingData ? "Loading..." : "Submit"}
            </button>
          </Form>
        </Container>
      </div>
    )
  }

  const Indeks = withFormik({
    mapPropsToValues({
      category,
      dateTime,
    }) {
      return {
        category: category || formValues.category,
        dateTime: dateTime || formValues.dateTime,
      }
    },
    validationSchema: Yup.object().shape({
      category: Yup.string().required("Choose one of category"),
      dateTime: Yup.string().required("Please select date time indeks"),
    }),
    handleSubmit(values) {
      const { category, dateTime } = values
      setFormValues({ category, dateTime })
      let posts
      let msg = ""
  
      console.log("LOGvalues ===> ", values)
  
      if (values?.category === "all") {
        if (values?.dateTime === now) {
          posts = masterPost;
        } else {
          posts = masterPost.filter(item => moment(item?.dateGmt).format('YYYY-MM-DD') === values?.dateTime);
        }
      } else {
        posts = masterPost.filter(item => item?.categorySlug === values?.category && moment(item?.dateGmt).format('YYYY-MM-DD') === values?.dateTime);
      }

      setFilteredPosts(posts)

      if (posts.length > 0) {
        msg = ""
      } else {
        msg = "Data not available"
      }

      setNothingPostMsg(msg)
    },
  })(FormIndeks)

  return (
    <Layout location={location}>
      <SEO title="Indeks" />

      <Section className="pb-6">
        <Indeks />
        {filteredPosts && filteredPosts.length > 0 ? (
          <Container>
            <div className="row col-md-12 m-0 px-0">
              {filteredPosts.slice(offset, perPage).map((item, i) =>{
                return(
                  <Card
                    key={i}
                    variant="basic"
                    colLeft="col-md-5 h-100"
                    colRight="col-md-7"
                    label={`${item?.categoryName} - ${getDate(item?.dateGmt, "EN", { time: false })}`}
                    title={item?.title}
                    text={item?.excerpt}
                    img={item?.featuredImage?.sourceUrl}
                    imgHeight="h-100"
                    cardBgClassName="h-100"
                    link={`/news-events/${item?.slug}/`}
                    className="card-post w-100"
                  />
                )
              })}
            </div>
            <div className="col-md-12 py-5 text-center">
              {filteredPosts.length > perPage ? (
                  <button 
                    className="btn btn-primary w-100 w-md-25"
                    onClick={handleLoadMore}
                  >
                    Load More
                  </button>
              ) : ""}
            </div>
          </Container>
        ):(
          <div className="nothing-post text-center font-weight-bold pb-5">
            <span>{nothingPostMsg}</span>
          </div>
        )}
      </Section>
    </Layout>
  )
}

export default IndeksPage
