import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, navigate, withPrefix } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { Section, Container } from "../../../components/grid/grid"
import faqImg from "../../../assets/img/faq-move-in-quickly.png"
import desktopLp from "../../../assets/img/infinite-living-mobile.jpg"
import icLocationBlack from "../../../assets/img/icons/icLocationBlack.png"
import { ReviveAd } from "../../../components/ads/reviveAds"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const changeParentSlug = slug => {
  switch (slug) {
    case "infiniteliving-shophouse":
      slug = "shophouse"
      break
    case "infiniteliving-residential":
      slug = "residential"
      break
    case "infiniteliving-kavling":
      slug = "kavling"
      break
    case "infiniteliving-apartment":
      slug = "apartment"
      break
  }
  return slug
}

const MoveInQuicklyFaqPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        propertyType(id: "infiniteliving-faq", idType: SLUG) {
          acfPropertyType {
            sliderImageBanner {
              link
              image {
                sourceUrl
              }
            }
            sliderImageMobile {
              link
              image {
                sourceUrl
              }
            }
            sliderImageMobileWdesc {
              ... on WordPress_PropertyType_Acfpropertytype_SliderImageMobileWdesc_Content {
                title
                description
                image {
                  sourceUrl
                }
                link {
                  url
                }
              }
            }
          }
        }
        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }
        propertyTypes(first: 150) {
          nodes {
            name
            slug
            propertyTypeId
            children {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  const [filteredLocations, setFilteredLocations] = useState("")
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState("")

  const wordPress = staticData.wordPress.propertyType
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const listPropertyTypes = staticData.wordPress.propertyTypes.nodes
  const listLocations = staticData.wordPress.locations.nodes
  const thisCurrrentUrl = process.env.BASE_URL + location.pathname

  let listImagesMobile = wordPress?.acfPropertyType?.sliderImageMobileWdesc

  const allProperty = { label: "All Property", value: " " }
  const allLocations = { label: "All Locations", value: " " }
  let listPropertyTypesParent = []
  let locations = []

  listPropertyTypes?.map(listItems => {
    if (listItems.slug === "infiniteliving") {
      listItems?.children?.nodes?.map(item => {
        let slug = changeParentSlug(item.slug)
        listPropertyTypesParent.push({ label: item.name, value: slug })
      })
    }
  })

  listLocations?.map(
    item =>
      item.slug !== "international-projects" &&
      item.slug !== "bekasi" &&
      item.slug !== "bogor" &&
      item.slug !== "cikarang" &&
      item.slug !== "depok" &&
      item.slug !== "karawang" &&
      item.slug !== "medan" &&
      item.slug !== "semarang" &&
      locations.push({ value: item.slug, label: item.name })
  )

  listPropertyTypesParent.splice(0, 0, allProperty)
  locations.splice(0, 0, allLocations)

  const locationHandler = e => {
    setFilteredLocations(e)
  }
  const propertyTypesHandler = e => {
    setFilteredPropertyTypes(e)
  }

  useEffect(() => {
    if (
      filteredPropertyTypes.value !== undefined &&
      filteredLocations.value !== undefined
    ) {
      navigate(
        "/moveinquickly/" +
          filteredPropertyTypes.value +
          "/" +
          filteredLocations.value
      )
    } else if (
      filteredPropertyTypes.value !== undefined &&
      filteredLocations.value === undefined
    ) {
      if (filteredPropertyTypes.value === " ") {
        navigate("/moveinquickly/")
      } else {
        navigate("/moveinquickly/" + filteredPropertyTypes.value, {
          state: {
            propertyType: filteredPropertyTypes.value,
          },
        })
      }
    } else if (
      filteredPropertyTypes.value === undefined &&
      filteredLocations.value !== undefined
    ) {
      if (filteredLocations.value === " ") {
        navigate("/moveinquickly/")
      } else {
        navigate("/moveinquickly/all-property/" + filteredLocations.value, {
          state: {
            location: filteredLocations.value,
          },
        })
      }
    }
  }, [filteredPropertyTypes, filteredLocations])

  useEffect(() => {
    if (!resizeScreen) {
      document.querySelector(".animated").style.marginTop = "0px"
    } else {
      document.querySelector(".animated").style.marginTop = "60px"
    }

    document.querySelector("#lang-wrapper").classList.remove("d-flex")
    document.querySelector("#lang-wrapper").classList.add("d-none")
  }, [])

  useEffect(() => {
    let navbarDesktop = document
      .getElementById("desktopNav")
      .querySelectorAll(".nav-link")

    if (resizeScreen) {
      document.querySelector(
        "#navbarMobile .logo-secondary"
      ).style.cssText = `display:none !important`
      document.querySelector(
        "#navbarMobile .logo-main"
      ).style.cssText = `display:block !important`
    } else {
      document.querySelector(
        "#navbarDesktop .logo-secondary"
      ).style.cssText = `display:none !important`
      document.querySelector(
        "#navbarDesktop .logo-main"
      ).style.cssText = `display:block !important`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO
        title={`Move in Quickly | FAQ Sinar Mas Land`}
        fbTitle={`Move in Quickly | FAQ Sinar Mas Land`}
        twitterTitle={`Move in Quickly | FAQ Sinar Mas Land`}
        twitterDescription={`Move in Quickly adalah Program Penjualan Nasional dari Sinar Mas Land. Program ini berlangsung dari bulan Januari sampai Desember 2025.`}
        fbDescription={`Move in Quickly adalah Program Penjualan Nasional dari Sinar Mas Land. Program ini berlangsung dari bulan Januari sampai Desember 2025.`}
        description={`Move in Quickly adalah Program Penjualan Nasional dari Sinar Mas Land. Program ini berlangsung dari bulan Januari sampai Desember 2025.`}
        url={thisCurrrentUrl}
        fbImg={
          listImagesMobile[0]?.image?.sourceUrl
            ? listImagesMobile[0]?.image?.sourceUrl
            : desktopLp
        }
        twitterImg={
          listImagesMobile[0]?.image?.sourceUrl
            ? listImagesMobile[0]?.image?.sourceUrl
            : desktopLp
        }
        img={
          listImagesMobile[0]?.image?.sourceUrl
            ? listImagesMobile[0]?.image?.sourceUrl
            : desktopLp
        }
      />
      <Helmet>
        <script src={withPrefix("slider.js")} type="text/javascript" />
        <body className="bd-page" />
      </Helmet>
      <Section
        className="header-smartmove-banner p-0 overflow-x-hidden"
        style={{ padding: "0px" }}
      >
        <div className="d-none d-lg-block">
          <ReviveAd zoneId="4" reviveId="4d3dce4db16ff385dd1c5dd5ade84a88" />
        </div>
        <div className="d-block d-lg-none">
          <ReviveAd zoneId="22" reviveId="4d3dce4db16ff385dd1c5dd5ade84a88" />
        </div>
      </Section>
      <Section className="main-faq">
        <Container className="mb-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-landmark form-icon-fa"></i>
                <Select
                  id="propertytypes"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Property Types"
                  options={listPropertyTypesParent}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => propertyTypesHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <img
                  alt="Location Icon"
                  className="form-icon"
                  src={icLocationBlack}
                />
                <Select
                  id="location"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Location"
                  options={locations}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => locationHandler(e)}
                />
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <Accordion preExpanded={[1]} allowZeroExpanded>
            <AccordionItem uuid={1}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  FAQ Move in Quickly Sinarmasland
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Accordion allowZeroExpanded>
                  <AccordionItem id="aci1">
                    <AccordionItemHeading id="ach1">
                      <AccordionItemButton>
                        1.Apa itu Move in Quickly?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Move in Quickly adalah program penjualan dari Sinar Mas Land yang menawarkan berbagai kemudahan dan keringanan bagi para konsumen. Program ini berlaku untuk berbagai proyek properti Sinar Mas Land mulai dari 22 Januari sampai akhir Desember 2025.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci2">
                    <AccordionItemHeading id="ach2">
                      <AccordionItemButton>
                        2. Kapan periode Move in Quickly berlangsung?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Program Move in Quickly berlangsung mulai dari 22 Januari– 31 Desember 2025.
                        <br />
                        Periode 1 : 22 Jan– 30 Apr 2025
                        <br />
                        Periode 2 : 1 Mei– 31 Aug 2025
                        <br />
                        Periode 3 : 1 Sep – 31 Des 2025
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci3">
                    <AccordionItemHeading id="ach3">
                      <AccordionItemButton>
                        3. Berapa besar keringanan yang ditawarkan pada periode 1?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div class="lg:mx-5">
                        <p class="mb-3 font-weight-bold">Untuk Kavling</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Periode 1
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 20%
                                <br />- Hadiah Konsumen 3.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPT Express DP 20%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 19%
                                <br />- Hadiah Konsumen 3.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPT Express DP 15%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 1%
                                <br />- Subsidi DP 15%
                                <br />- Hadiah Konsumen 3.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 13%
                                <br />- Hadiah Konsumen 3.5%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p>
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>- Free 1x Admin “Biaya Pengalihan”.</span>
                          <br />
                          <span>
                            - Tambahan disc + 5% untuk Kav Irregular / Tusuk Sate
                          </span>
                          <br />
                          <span>
                            - Tambahan disc + 3% untuk Kav The Nove Phase 1 & 2
                          </span>
                          <br />
                        </p>
                        <p class="mb-1 font-weight-bold">Untuk Non Apartment</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Category 2
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Category 3A
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Category 3B
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 20% <br />
                                - Hadiah Konsumen 3.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 10% <br />
                                - Hadiah Konsumen 1.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 10% <br />
                                - Hadiah Konsumen 3.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPT Express DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 8% <br />
                                - Subsidi DP 10% <br />
                                - Hadiah Konsumen 3.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 1.5% <br />
                                - Subsidi DP 10%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">
                            Product Category 2 :{" "}
                          </span>
                          <br />
                          <span>
                            Alfiore - TBW, Kios Pasar Modern - TBW, Flathouse, Askara Nue, Whitsand II, Whelford, Batam Landed Ph. 1 (Blok ABCD), Zena, Grand Palais, Garden Suite, Autoparts, Kios Pasar Modern 2/ Barat, Alegria Park, Alesha, Assana
                          </span>
                          <br />

                          <span className="text-underline">
                            Product Category 3A :{" "}
                          </span>
                          <br />
                          <span>
                            Imajihaus, Impresahaus, The Savia Tahap 1, Visana at The Savia, Caelus, Azura Starlight, Batam Landed Ph. 1 (Blok ABCD),  Batam Landed Ph. 2 (Blok EFGH), Aure
                          </span>
                          <br />

                          <span className="text-underline">
                            Product Category 3B :{" "}
                          </span>
                          <br />
                          <span>
                            The Loop, Delrey Business Townhouse 2, Taman Tekno X, Cheville, Ruko Madrid X, Atherton, The Blizfield, Latinos Business District Thp 3, Tanakayu Jiva, Tanakayu Svani, Tanakayu Svadhi, Tanakayu Svasti, Tanakayu Chava, Tanakayu Vasya, Aerra, Grand Freja, Nordville, New Palladium, London Park Avenue Thp 2, New Alesha, Vanya Avenue, Excelia, Virginia Arcade Thp 2
                          </span>
                          <br />
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Category
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Additional Discount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold text-center">
                                2
                              </td>
                              <td class="border px-4 py-2">
                                + 10% untuk Kios Autoparts BSD <br/>
                                + 5% untuk Whitsand + Free Furniture
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold text-center">
                                3B
                              </td>
                              <td class="border px-4 py-2">
                                + 1% untuk Taman Tekno X <br />
                                + 2% untuk Ruko The Loop <br />
                                + 3% untuk Latinos Business District <br />
                                + 3% untuk Ruko Madrid X
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p class="mb-1 font-weight-bold">Untuk Apartment</p>
                        <p class="mb-3 font-weight-bold">
                          Promo Category  - The Elements
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                The Elements
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 5.5% <br />
                                - Furniture 10% <br />
                                - Hadiah Konsumen 1%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPA Express DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 5% <br />
                                - Furniture 10% <br />
                                - Hadiah Konsumen 1%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment DP 50% 1x, 50% 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 1.5% <br />
                                - Furniture 10% <br />
                                - Hadiah Konsumen 1%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Furniture 10%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>
                            - Tambahan disc +  2% : Tower 1 Unit D dan Tower 2 Unit A.
                          </span>
                          <br />
                          <span>
                            - Tambahan disc +  4% : Tower 1 Unit C dan Tower 2 Unit B.
                          </span>
                          <br />
                        </p>
                        <p class="mb-1 font-weight-bold">
                          Promo Category - Southgate (Altuera, Elegance dan Prime)
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Altuera
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Elegance
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Prime
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 17% <br />
                                - Furniture 6% <br />
                                - Hadiah Konsumen 2%<br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 18.5% <br />
                                - Furniture 7.5% <br />
                                - Hadiah Konsumen 2% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 25% <br />
                                - Furniture 7.5% <br />
                                - Hadiah Konsumen 4% <br />
                                - Free IPL 1%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPA Express DP 15%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 15% <br />
                                - Furniture 6% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 15% <br />
                                - Furniture 7.5% <br />
                                - Hadiah Konsumen 1.5% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 5% <br />
                                - Subsidi DP 15% <br />
                                - Free KPA Fee 2,5% <br />
                                - Furniture 7.5% <br />
                                - Free IPL 1% <br />
                                - Hadiah Konsumen 4%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment DP 50% 1x, 50% 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 13% <br />
                                - Furniture 6% <br />
                                - Hadiah Konsumen 2% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 14.5% <br />
                                - Furniture 7.5% <br />
                                - Hadiah Konsumen 2% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 20% <br />
                                - Furniture 7.5% <br />
                                - Free IPL 1% <br />
                                - Hadiah Konsumen 4%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 40% 2x, 60% 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 9% <br />
                                - Furniture 6% <br />
                                - Hadiah Konsumen 2% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 9.5% <br />
                                - Furniture 7.5% <br />
                                - Hadiah Konsumen 2% <br />
                                - Free IPL 1%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 16% <br />
                                - Furniture 7.5% <br />
                                - Free IPL 1% <br />
                                - Hadiah Konsumen 4%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>
                            - SG Altuera: Tambahan disc +3% untuk Unit Non-Studio; tambahan disc +6% untuk Unit Loft+
                          </span>
                          <br />
                          <span>
                            - SG Elegance: Tambahan disc +3% untuk Unit B,C,F, dan K.
                          </span>
                          <br />
                          <span>
                            - SG Prime : Tambahan disc +1.5% untuk Unit A, C, dan D
                          </span>
                          <br />
                        </p>
                        <p class="mb-1 font-weight-bold">
                          Promo Category - Aerium
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Aerium
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 19.5% <br />
                                - Hadiah Customer 1% <br />
                                - Free IPL 1% <br />
                                - Furniture 5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPA Express DP 15%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 15% <br />
                                - Hadiah Customer 1.5% <br />
                                - Free IPL 1% <br />
                                - Furniture 5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 50% 1x; 50% 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 14% <br />
                                - Hadiah Customer 1% <br />
                                - Free IPL 1% <br />
                                - Furniture 5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 12x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 11.5% <br />
                                - Hadiah Customer 1% <br />
                                - Free IPL 1% <br />
                                - Furniture 5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 20% 2x; 80% 36x
                              </td>
                              <td class="border px-4 py-2">
                                - Furniture 7%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>
                            - Tambahan disc +3%  untuk unit #9 (2BR-C) dan #10 (2BR-B) Hadap Utara
                          </span>
                          <br />
                        </p>
                        <p class="mb-1 font-weight-bold">
                          Promo Category - Batam (Kalani dan Kaina)
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Kalani
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Kaina
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 8% <br />
                                - Furniture 9%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 8% <br />
                                - Hadiah Konsumen 5% <br />
                                - Furniture 10% <br />
                                - Free BPHTB
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPA Express DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10% <br />
                                - Free KPA Fee 2.5% <br />
                                - Hadiah Konsumen 3%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10% <br />
                                - Free KPA Fee 2% <br />
                                - Hadiah Konsumen 5% <br />
                                - Free BPHTB
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 6x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 4% <br />
                                - Furniture 9%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 5% <br />
                                - Hadiah Konsumen 1.5% <br />
                                - Furniture 10% <br />
                                - Free BPHTB
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>
                            - Tambahan disc +3%  untuk unit Tipe Studio
                          </span>
                          <br />
                        </p>
                        <p class="mb-1 font-weight-bold">
                          Promo Category - BSD City (Asatti dan Casa de Parco)
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Asatti dan Casa de Parco
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Hard Cash
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 15% <br />
                                - Furniture 7%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPA Express DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 4% <br />
                                - Subsidi DP 10% <br />
                                - Furniture 7%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>
                            - Tambahan disc +5%  untuk Unit Casa De Parco
                          </span>
                          <br />
                        </p>
                        <p class="mb-1 font-weight-bold">
                          Promo Category - Surabaya (Klaska)
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                Term of Payment
                              </th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">
                                5H: Klaska
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                KPA Express DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10%
                                <br />
                                - Furniture 9%
                                <br />
                                - Free BPHTB
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">
                                Installment 6x
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 7.5%
                                <br />
                                - Furniture 9%
                                <br />
                                - Free BPHTB
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span>
                          <br />
                          <span>
                            - Tambahan disc +3% untuk Deluxe – 1 Bedroom.
                          </span>
                          <br />
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci4">
                    <AccordionItemHeading id="ach4">
                      <AccordionItemButton>
                        4. Proyek apa saja yang ditawarkan dalam program Move in Quickly ? Dan dimana lokasinya ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        BSD City residential dan commercial, Kota Wisata & Legenda Wisata - Cibubur, Nuvasa Bay - Batam, Klaska, Paddington & Wisata Bukit Mas - Surabaya, Grand City Balikpapan - Balikpapan, The Elements - Jakarta, Aerium - Jakarta, Southgate – Jakarta, Taman Banjar Wijaya Tangerang.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci5">
                    <AccordionItemHeading id="ach5">
                      <AccordionItemButton>
                        5. Jenis Produk apa saja yang ditawarkan dalam program Move in Quickly ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Rumah, Kavling, Apartemen dan Ruko.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci6">
                    <AccordionItemHeading id="ach6">
                      <AccordionItemButton>
                        6. Bagaimana cara mendapatkan keringanan selama program Move in Quickly?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <img src={faqImg} style={{ width: "100%" }} />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci7">
                    <AccordionItemHeading id="ach7">
                      <AccordionItemButton>
                        7. Di platform mana saja dapat melakukan pembelian produk program Move in Quickly ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Bisa dengan mengunjungi <a href="/moveinquickly">www.sinarmasland.com/moveinquickly</a>, menghubungi property agent/sales marketing Sinar Mas Land atau kunjungi social media IG @sinarmas_land , FB Sinar Mas Land. <br />
                        Selain itu, dapat juga bertransaksi melalui e-Commerce yang sudah bekerja sama dengan Sinar Mas Land seperti Tokopedia dan Blibli
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Section>
    </Layout>
  )
}
export default MoveInQuicklyFaqPage
