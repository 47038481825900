export function decodeHTMLEntities(text) {
    const entities = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
    };
    
    // Create a regex pattern matching all entities (using word boundaries for precise matching)
    const pattern = new RegExp(Object.keys(entities).join('|'), 'g');
    
    // Replace each entity with its corresponding symbol
    return text.replace(pattern, (match) => entities[match] || match);
}

// Example usage:
// console.log(decodeHTMLEntities('Hello &amp; World')); // Output: "Hello & World"
// console.log(decodeHTMLEntities('Copyright &copy; 2024')); // Output: "Copyright © 2024"
// console.log(decodeHTMLEntities('1 &lt; 2 &amp;&amp; 3 &gt; 2')); // Output: "1 < 2 && 3 > 2"