import gql from "graphql-tag"

export const GET_PROJECT_DETAIL = gql`
  query ProjectDetails($slug: String!) {
    projectBy(uri: $slug) {
      title
      slug
      excerpt
      propertyTypes {
        nodes {
          name
          slug
        }
      }
      locations {
        nodes {
          slug
          name
        }
      }
      blocks {
        ... on CoreEmbedBlock {
          name
          attributes {
            url
          }
        }
        ... on AcfVirtualLinkBlock {
          acf {
            title
            cards {
              title
              description
              image {
                mediaItemUrl
              }
              link {
                title
                url
                target
              }
            }
          }
        }
        ... on CoreCoverBlock {
          parentId
          name
          attributes {
            url
          }
        }
        ... on CoreParagraphBlock {
          parentId
          name
          attributes {
            ... on CoreParagraphBlockAttributesV3 {
              content
            }
          }
        }
        ... on CoreHeadingBlock {
          parentId
          name
          attributes {
            content
            level
          }
        }
        ... on CoreImageBlock {
          parentId
          name
          attributes {
            url
            title
          }
        }
        name
        ... on CoreButtonsBlock {
          parentId
          name
          innerBlocks {
            name
            ... on CoreButtonBlock {
              parentId
              attributes {
                ... on CoreButtonBlockAttributes {
                  text
                  url
                }
                ... on CoreButtonBlockAttributesV2 {
                  text
                  url
                }
              }
            }
          }
        }
        ... on CoreShortcodeBlock {
          parentId
          name
          attributes {
            text
          }
        }
        ... on CoreGroupBlock {
          parentId
          name
          attributes{
            className
          }
          innerBlocks {
            name
            ... on CoreHeadingBlock {
              parentId
              name
              attributes {
                content
                level
              }
            }
            ... on CoreParagraphBlock {
              parentId
              name
              attributes {
                ... on CoreParagraphBlockAttributesV3 {
                  align
                  backgroundColor
                  content
                }
              }
            }
            ... on CoreButtonsBlock {
              parentId
              name
              innerBlocks {
                name
                ... on CoreButtonBlock {
                  parentId
                  name
                  attributes {
                    ... on CoreButtonBlockAttributes {
                      text
                      url
                    }
                    ... on CoreButtonBlockAttributesV2 {
                      text
                      url
                    }
                  }
                }
              }
            }
            ... on CoreImageBlock {
              parentId
              name
              attributes {
                url
                title
              }
            }
            ... on AcfIconlistBlock {
              parentId
              acf {
                list {
                  icon {
                    sourceUrl
                  }
                  title
                  description
                }
              }
            }
            ... on AcfLabelvaluesBlock {
              parentId
              acf {
                list_label_values {
                  label
                  value
                }
              }
            }

            ... on AcfAcfgalleryBlock {
              parentId
              name
              acf {
                carousel_type
                images {
                  title
                  mediaDetails {
                    file
                    height
                    width
                  }
                  slug
                  sourceUrl
                  acfAttachment {
                    location
                    expired
                  }
                  caption
                  description
                }
              }
            }
          }
        }

        ... on AcfIconlistBlock {
          parentId
          acf {
            list {
              icon {
                sourceUrl
              }
              title
              description
            }
          }
        }
        ... on AcfLabelvaluesBlock {
          parentId
          acf {
            list_label_values {
              label
              value
            }
          }
        }
        ... on AcfAcfgalleryBlock {
          parentId
          name
          acf {
            carousel_type
            images {
              title
              mediaDetails {
                file
                height
                width
              }
              slug
              sourceUrl
              acfAttachment {
                location
                expired
              }
              caption
              description
            }
          }
        }
        ... on AcfEBrochureBlock {
          acf {
            pdf_link {
              target
              title
              url
            }
          }
        }
      }
      nearby
      projectCustomfields {
        longitude
        latitude
        phoneNumber
        preferredPriceRangeFrom
        preferredPriceRangeTo
        preferredBathsRangeFrom
        preferredBathsRangeTo
        preferredBedRangeFrom
        preferredBedRangeTo
        projectTypeBlueprint {
          typeTitle
          typeImages {
            sourceUrl
          }
          typeDescription
          typeSpecs{
            typeSpecText
            typeSpecIcon{
              sourceUrl
            }
          }
        }
      }
      embedProjectsLocation{
        embedUrl
      }
      embedProjectsVR360{
        vr360EmbedUrl
      }
      priceProduct{
        price
        promoText
      }
      projectId
    }
  }
`
export const GET_NEARBY_PROJECT = gql`
  query ProjectQuery(
    $id: [ID]
    $slug: [String]
    $language: LanguageCodeFilterEnum
    $notId: [ID]
  ) {
    projects(where: { in: $id, language: $language, notIn: $notId }) {
      edges {
        node {
          title
          slug
          excerpt
          featuredImage {
            id
            title
            sourceUrl
            caption
          }
          locations {
            nodes {
              name
              slug
            }
          }
          facilities{
            nodes{
              name
              slug
            }
          }
          propertyTypes {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    propertyTypes(where: { slug: $slug }) {
      nodes {
        name
        slug
        projects {
          nodes {
            title
            slug
            featuredImage {
              sourceUrl
              caption(format: RAW)
            }
            locations {
              nodes {
                slug
              }
            }
          }
        }
      }
    }
  }
`

export const FIND_PROPERTY = gql`
  query Finder(
    $facilityId: [ID]
    $locationId: [ID]
    $typeId: [ID]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    projects(
      where: {
        facilityIn: $facilityId
        locationIn: $locationId
        propertyTypeIn: $typeId
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          databaseId
          title
          slug
          excerpt
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            caption
          }
        }
      }
      pageInfo {
        total
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
`

export const FETCH_EBROCHURE = gql`
  query brochure($id: ID!) {
    eBrochure(id: $id, idType: URI) {
      eBrochure {
        file {
          mediaItemUrl
        }
      }
    }
  }
`
