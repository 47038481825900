import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import moment from "moment"
import ReactMarkdown from "react-markdown/with-html"
import { InstagramEmbed } from 'react-social-media-embed';

import { LangContext } from "../context/langContext"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { FIND_NEWS_BY_TAGS } from "../graphql/tags-graphql"

import { Button } from "../components/buttons/buttons"
import { Section, Container } from "../components/grid/grid"
import { PostHeader, PostContent } from "../components/post/post"
import { SocmedLinks } from "../components/socmed-links/socmed-links"
import { CoverFeatured } from "../components/cover-featured/cover-featured"
import { Cover } from "../components/cover/cover"
import { ReviveAd } from "../components/ads/reviveAds"

import { stringToSlug } from "../utils/text-to-slug"
import { isInstagramUrl } from "../utils/url-detection"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Headings = ({ data }) => {
  if (data.attributes.level === 2) {
    return <h2 dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h2>
  } else if (data.attributes.level === 3) {
    return <h3 dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h3>
  } else if (data.attributes.level === 4) {
    return <h4 dangerouslySetInnerHTML={{__html: data?.attributes?.content,}}></h4>
  }
  return <div></div>
}

const News = props => {
  const staticData = useStaticQuery(graphql`
    query {
      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      allNewsJson {
        edges {
          node {
            newsItems {
              id
              category
              title
              excerpt
              date(formatString: "MMM DD, YYYY")
              url
              img {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { lang, setLang } = useContext(LangContext)
  const langKey = (props?.location?.pathname?.split('/')[1] || props?.location?.uri?.split('/')[1]) === "id" ? "ID" : lang === "ID" ? "ID" : "EN"
  const redirectAmpHtml = process.env.BASE_URL + props.location.pathname
  const newsItem = staticData.allNewsJson.edges[0].node.newsItems[1]
  const newsItems =
    props.pathContext.langKey === "ID"
      ? props.pathContext?.data?.node?.translation ||
        props.pathContext?.data.node ||
        props.pathContext?.data
      : props.pathContext?.data?.node
  const seo = newsItems?.seo
  const categoryNews = props?.pathContext?.categoryNews
  const titleNewsSchema = newsItems?.title
    ?.replace(/(<([^>]+)>)/gi, "")
    ?.replace(/&#8211;/gi, "-")
    ?.replace(/&#038;/gi, "&")
    ?.replace(/&#8216;/gi, "'")
    ?.replace(/&#8221;/gi, '"')

  const featuredImage =
    props.pathContext?.data?.node?.featuredImage?.mediaItemUrl

  let newsTags = []
  const listRelatedNews = []
  const thisPageSlug = props.location.pathname.replace("/news/", "")

  props?.pathContext?.data?.node?.tags?.nodes.forEach(item => {
    newsTags.push(item.slug)
  })
  const newsTagsSelection = newsTags.length > 0 && newsTags[Math.floor(Math.random()*newsTags.length)]

  const { data: relatedNews } = useQuery(FIND_NEWS_BY_TAGS, {
    variables: { tagsNews: newsTagsSelection },
  })

  relatedNews?.tags?.nodes.forEach(tags => {
    tags.posts.edges.forEach((item) => {
      if(thisPageSlug !== item.node.slug && listRelatedNews.length < 5){
        listRelatedNews.push({
          image: item?.node?.featuredImage?.sourceUrl,
          title: item?.node?.title,
          slug: item?.node?.slug,
          date: item?.node?.date,
        })
      }
    })
  })

  const schema = [
    {
      "@context" : "http://schema.org",
      "@type" : "Organization",
      "name" : "Sinarmasland",
      "url" : "https://www.sinarmasland.com",
      "logo" : {
        "@type" : "ImageObject",
        "url" : "https://www.sinarmasland.com/static/logo_sinarmas-light-eee193bd58f1076f40d39fb926ae8100.png",
        "width" : "307",
        "height" : "120"
      },
      "sameAs" : [
        "https://www.facebook.com/sinarmasland",
        "https://twitter.com/sinarmas_land",
        "https://www.instagram.com/sinarmas_land/",
        "https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg"
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Sinar Mas Land",
        "item": "https://www.sinarmasland.com/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Berita",
        "item": "https://www.sinarmasland.com/news"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": titleNewsSchema,
        "item": redirectAmpHtml
      }]
    }
  ]

  const BreadCrumbsNews = ({category, date}) => {
    return(
      <>
        <ol className={`breadcrumb breadcrumb-dark d-inline-flex`}>
          <li className="breadcrumb-item-news" key={`path-home`}>
            <Link to="/" className="fx-underline">
              <i className="fa fa-home" aria-hidden="true"></i>
            </Link>
          </li>
          <li className="breadcrumb-item-news">
            <Link to="/news" className="fx-underline">
              <span dangerouslySetInnerHTML={{__html: category}} className="category-news"/>
            </Link>
          </li>
        </ol>
        <span className="separator">&nbsp;&nbsp;•</span>&nbsp;&nbsp;<span className="date">{moment(date).format("LL")}</span>
      </>
    )
  }

  const truncateString = (words, maxLength) => {
    const index = words.indexOf(" ", maxLength);
    return index === -1 ? words : words.substring(0, index).concat("...")
  }

  const handleCopy = () => {
    const thisInput = document.createElement("INPUT")

    document.body.append(thisInput)
    thisInput.value = redirectAmpHtml
    thisInput.select()
    document.execCommand("copy")
    thisInput.remove()
    setTimeout(function(){document.getElementById('copyButton').innerHTML = `<i class="fa fa-link"></i> <span class="tooltiptext">${langKey === "EN" ? "URL has copied" : "Tautan telah disalin"}</span>`;}, 200)
    setTimeout(function(){document.getElementById('copyButton').innerHTML = '<i class="fa fa-link"></i>';}, 2000)
  }

  useEffect(() => { setLang(langKey) }, [langKey])

  useEffect(() => {
    document.head.querySelectorAll(`[rel="amphtml"]`)[0].href = redirectAmpHtml.replace('/news/', '/news/amp/')
  }, [])

  return (
    <Layout
      location={props.location}
      lang={props.pathContext.langKey}
      newsLangSlug={
        props?.pathContext?.translation?.node?.slug ||
        props?.pathContext?.translation?.slug ||
        props?.pathContext?.data?.slug ||
        newsItems.slug
      }
      isNews={true}
    >
      <Helmet>
        <body className="bd-post-details" />
        <link rel="amphtml" href={redirectAmpHtml.replace('/news/', '/news/amp/')}/>
        <link rel="canonical" href={redirectAmpHtml}/>
        <meta name="news_keywords" content={seo?.focuskw ? seo?.focuskw : ""}></meta>
      </Helmet>
      <SEO
        title={seo?.title}
        description={seo?.opengraphDescription}
        fbTitle={seo?.opengraphTitle || seo?.title}
        fbDescription={seo?.opengraphDescription}
        twitterTitle={seo?.twitterTitle || seo?.title}
        twitterDescription={
          seo?.twitterDescription || seo?.opengraphDescription
        }
        img={featuredImage}
        fbImg={featuredImage}
        twitterImg={featuredImage}
        url={redirectAmpHtml}
        ampUrl={true}
        type="article"
        schemaMarkup={schema}
      />

      <Section className="post-details">
        <Container>
          <div class="row">
            <div className="col-lg-3 related-news-wrapper">
              {newsTags.length > 0 && (
                <>
                  <div className="row col-xs-12 col-sm-12 col-lg-12 p-0 pb-3 related-head">
                    <div className="col-xs-6 col-sm-6 text-left">
                      <p className="related-news-title">{props.pathContext.langKey === "ID"
                        ? "Berita Terkait"
                        : "Related News"}</p>
                    </div>
                    <div className="col-xs-6 col-sm-6 text-right">
                      <Button
                        variant="link"
                        link={props.pathContext.langKey === "ID" ? "/id/news" : "/news"}
                        className="view-all-text"
                      >
                        {props.pathContext.langKey === "ID"
                          ? "Lihat Semua   "
                          : "View All   "}
                          <i className="fas fa-chevron-right"  />
                      </Button>
                    </div>
                  </div>
                  {listRelatedNews.map((item,i) =>{
                    return(
                      <div 
                        key={i} 
                        className="row col-lg-12 p-0 list-related-news"
                      >
                        <div className="col-lg-5">
                          <img className="image" src={item.image}/>
                        </div>
                        <div className="col-lg-7">
                          <a 
                            className="title"
                            href={props.pathContext.langKey === "ID" 
                              ? `/id/news/${item.slug}`
                              : `/news/${item.slug}`}
                            dangerouslySetInnerHTML={{
                              __html: truncateString(item.title, 40)
                            }}
                          >
                          </a>
                          <p 
                            className="date"
                          >
                            {moment(item.date).format("LL")}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
            <div className="col-lg-7 detail-news">
              <PostHeader
                title={
                  newsItems &&
                  newsItems?.title
                    ?.replace(/(<([^>]+)>)/gi, "")
                    ?.replace(/&#8211;/gi, "-")
                    ?.replace(/&#038;/gi, "&")
                    ?.replace(/&#8216;/gi, "'")
                    ?.replace(/&#8221;/gi, '"')
                }
                // category={newsItem?.category}
                // date={newsItems && moment(newsItems.date).format("LL")}
                imgAlt={
                  newsItems && newsItems?.featuredImage
                    ? newsItems?.title
                    : null
                }
              />

              <Section className={`p-0`}>
                <BreadCrumbsNews
                  category={categoryNews}
                  date={newsItems && moment(newsItems.date).format("LL")}
                />
              </Section>

              <SocmedLinks
                facebookLink={props.location.href}
                twitterLink={props.location.href}
                linkedinLink={props.location.href}
                copyLink={redirectAmpHtml}
                className="mb-4"
                onClick={handleCopy}
                isAmp={false}
                location={props.location}
              />

              <PostContent>
                {newsItems &&
                  newsItems?.blocks?.map((item, i) => {
                    switch (item.name) {
                      case "core/heading":
                        return <Headings key={`heading-${i}`} data={item} />
                      case "core/paragraph":
                        return (
                          item?.attributes?.content && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item?.attributes?.content,
                              }}
                            />
                          )
                        )
                      case "core/image":
                        return (
                          <div key={`core-image-${i}`} className="pb-4">
                            <img
                              alt="img-bsd-train-station"
                              src={item?.attributes?.url}
                              className="img-fluid"
                            />
                            <p key={`caption-${i}`} className="text-center m-0" dangerouslySetInnerHTML={{ __html: item?.attributes?.caption}} style={{ fontSize: "16px"}}/>
                          </div>
                        )
                      case "core/cover":
                        return (
                          <div key={`featured-${i}`} className="pb-4">
                            <CoverFeatured
                              img={
                                item?.attributes?.url ||
                                staticData?.featured?.childImageSharp?.fluid
                              }
                              title=""
                              text=""
                            />
                          </div>
                        )
                      case "core/list":
                        return (
                          <div
                            className="heading heading-basic"
                            style={{ maxWidth: "100%", textAlign: "justify" }}
                          >
                            {item.attributes.start !== null && (
                              <ol
                                start={item.attributes.start}
                                style={{ paddingLeft: "20px", color: "#333" }}
                              >
                                <ReactMarkdown
                                  source={item.attributes.values}
                                  escapeHtml={false}
                                />
                              </ol>
                            )}
                            {item.attributes.start === null && (
                              <ul
                                style={{
                                  listStyleType: "disk",
                                  paddingLeft: "20px",
                                  color: "#333",
                                }}
                              >
                                <ReactMarkdown
                                  source={item.attributes.values}
                                  escapeHtml={false}
                                />
                              </ul>
                            )}
                          </div>
                        )    
                      case "core/embed":
                        if(!item.attributes?.url) return null
                        if(isInstagramUrl(item.attributes.url)){
                          return <InstagramEmbed url={item.attributes.url} captioned />
                        }
                        return (
                          <div className="py-main">
                            <Cover
                              variant="basic"
                              theme="dark"
                              img={null}
                              imgHeight="post-detail-video"
                              videoSrc={item.attributes.url}
                              type={{ name: "core-embed/youtube" }}
                              audio={true}
                            />
                          </div>
                        )
                      default:
                        return <div key={`empty-${i}`}></div>
                    }
                  })}
              </PostContent>
            </div>
            <div className="col-lg-2 news-ads-wrapper">
              <ReviveAd 
                zoneId="2" 
                reviveId="4d3dce4db16ff385dd1c5dd5ade84a88" 
              />
            </div>
            <div className="col-lg-12 related-news-wrapper mv mt-3">
              {newsTags.length > 0 && (
                <>
                  <div className="row col-xs-12 col-sm-12 col-lg-12 p-0 pb-3 related-head">
                    <div className="col-xs-6 col-sm-6 text-left">
                      <p className="related-news-title">{props.pathContext.langKey === "ID"
                        ? "Berita Terkait"
                        : "Related News"}</p>
                    </div>
                    <div className="col-xs-6 col-sm-6 text-right">
                      <Button
                        variant="link"
                        link={props.pathContext.langKey === "ID" ? "/id/news" : "/news"}
                        className="view-all-text"
                      >
                        {props.pathContext.langKey === "ID"
                          ? "Lihat Semua   "
                          : "View All   "}
                          <i className="fas fa-chevron-right"  />
                      </Button>
                    </div>
                  </div>
                  {listRelatedNews.map((item,i) =>{
                    return(
                      <div 
                        key={i} 
                        className="row col-lg-12 p-0 list-related-news"
                      >
                        <div className="col-lg-5">
                          <img className="image" src={item.image}/>
                        </div>
                        <div className="col-lg-7">
                          <a 
                            className="title"
                            href={props.pathContext.langKey === "ID" 
                              ? `/id/news/${item.slug}`
                              : `/news/${item.slug}`}
                          >
                            {item.title}
                          </a>
                          <p 
                            className="date"
                          >
                            {moment(item.date).format("LL")}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default News
