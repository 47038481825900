import React, { useContext } from "react"
import { withFormik } from "formik"
import { navigate } from "gatsby"
import { Field } from "formik"
import Select from "react-select"
import * as Yup from "yup"

import { LangContext } from "../../context/langContext"

import icLocationBlack from "../../assets/img/icons/icLocationBlack.png"
import icBadroomBlack from "../../assets/img/icons/icBadroomBlack.png"
import icBathroomBlack from "../../assets/img/icons/icBathroomBlack.png"
import icEmployeeBlack from "../../assets/img/icons/icEmployeeBlack.png"
import icSizeBlack from "../../assets/img/icons/icSizeBlack.png"

const Checkbox = props => {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={props.value}
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    value => value !== props.value
                  )
                  form.setFieldValue(props.name, nextValue)
                } else {
                  const nextValue = field.value.concat(props.value)
                  form.setFieldValue(props.name, nextValue)
                }
              }}
            />
            <label className="custom-control-label" for={props.value}>
              {props.label}
            </label>
          </div>
        </div>
      )}
    </Field>
  )
}

const DefaultCheckbox = props => {
  return (
    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={props.value}
          onChange={props.onChange}
          // checked={props.value}
          // onChange={() => {
          //   if (field.value.includes(props.value)) {
          //     const nextValue = field.value.filter(
          //       value => value !== props.value
          //     )
          //     form.setFieldValue(props.name, nextValue)
          //   } else {
          //     const nextValue = field.value.concat(props.value)
          //     form.setFieldValue(props.name, nextValue)
          //   }
          // }}
        />
        <label className="custom-control-label" for={props.value}>
          {props.label}
        </label>
      </div>
    </div>
  )
}

export const MySelect = ({
  options,
  id,
  error,
  touched,
  value,
  placeholder,
  iconSrc,
  alt,
  onChange,
  onBlur,
}) => {
  const handleChange = value => {
    onChange(id, value)
  }

  const handleBlur = () => {
    onBlur(id, true)
  }

  return (
    <>
      <div className="form-group d-flex align-items-center">
        <img alt={alt} className="form-icon" src={iconSrc} />
        <Select
          id={id}
          className="form-select w-100"
          classNamePrefix="select"
          options={options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          placeholder={placeholder}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#e5e5e5",
              primary: "#191919",
            },
          })}
        />
      </div>
      {!!error && touched && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </>
  )
}

const DefaultSelect = ({
  iconSrc,
  iconFa,
  id,
  placeholder,
  alt,
  options,
  isDisabled,
  isLoading,
  onChange,
  name,
  value,
}) => {
  return (
    <>
      <div className="form-group d-flex align-items-center">
        {iconSrc && <img alt={alt} className="form-icon" src={iconSrc} />}
        {iconFa && <i className={`${iconFa} form-icon-fa`}></i>}
        <Select
          id={id}
          className="form-select w-100"
          classNamePrefix="select"
          isDisabled={isDisabled}
          isLoading={isLoading}
          placeholder={placeholder}
          options={options}
          name={name}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#e5e5e5",
              primary: "#191919",
            },
          })}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  )
}

const ResidenceComponent = ({
  locations,
  values,
  touched,
  errors,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
}) => {
  return (
    <div className="tab-content visible">
      {/* <span className="form-label">Residence</span> */}
      <form onSubmit={handleSubmit}>
        <div className="row row-3 mb-3 mb-md-4">
          <div className="col-lg-4 col-12">
            <MySelect
              alt="bathroom icon"
              iconSrc={icLocationBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="location"
              value={values.location}
              error={errors.location}
              touched={touched.location}
              placeholder="Residence location"
              options={locations}
            />
          </div>
          <div className="col-lg-4 col-12">
            <MySelect
              alt="bathroom icon"
              iconSrc={icBadroomBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="bedroom"
              value={values.bedroom}
              error={errors.bedroom}
              touched={touched.bedroom}
              placeholder="Number of bedroom"
              options={[
                { value: "1", label: "1 bedroom" },
                { value: "2", label: "2 bedroom" },
                { value: "3", label: "3 bedroom" },
                { value: "4", label: "4 bedroom" },
              ]}
            />
          </div>
          <div className="col-lg-4 col-12">
            <MySelect
              alt="bathroom icon"
              iconSrc={icBathroomBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="bathroom"
              value={values.bathroom}
              error={errors.bathroom}
              touched={touched.bathroom}
              placeholder="Number of bathroom"
              options={[
                { value: "1", label: "1 bathroom" },
                { value: "2", label: "2 bathroom" },
                { value: "3", label: "3 bathroom" },
                { value: "4", label: "4 bathroom" },
              ]}
            />
          </div>
        </div>
        <div className="mb-3 mb-md-4">
          <span className="form-label">Facilities</span>

          <div className="select-box-wrapper">
            <div className="row row-3">
              <div className="col-md-4 col-6">
                <Checkbox
                  name="residenceFacilities"
                  value="security-24-7"
                  label="Security 24/7"
                />
                <Checkbox
                  name="residenceFacilities"
                  value="pasar-modern"
                  label="Fresh Modern Market"
                />
              </div>
              <div className="col-md-4 col-6">
                <Checkbox
                  name="residenceFacilities"
                  value="jogging-track"
                  label="Jogging Track"
                />
                <Checkbox
                  name="residenceFacilities"
                  value="taman-kota"
                  label="Green Spaced Garden"
                />
              </div>
              <div className="col-md-4 col-6">
                <Checkbox
                  name="residenceFacilities"
                  value="swimming-pool"
                  label="Swimming Pool"
                />
              </div>
            </div>
            {errors.residenceFacilities && touched.residenceFacilities && (
              <div className="invalid-feedback d-block">
                {errors.residenceFacilities}
              </div>
            )}
          </div>
        </div>
        {isSubmitting ? (
          <button className="btn btn-primary btn-block" type="submit" disabled>
            <i class="fa fa-spinner fa-spin"></i>
          </button>
        ) : (
          <button className="btn btn-primary btn-block" type="submit">
            Find Residence Property
          </button>
        )}
      </form>
    </div>
  )
}
const formikEnhancerResidence = withFormik({
  mapPropsToValues({ location, bedroom, bathroom, residenceFacilities }) {
    return {
      location: location || "",
      bedroom: bedroom || "",
      bathroom: bathroom || "",
      residenceFacilities: residenceFacilities || [],
    }
  },
  handleSubmit(values) {
    setTimeout(() => {
      return navigate("/search-result/", {
        state: { values, propertyType: "Residence" },
        replace: true,
      })
    }, 500)
  },
})
const MyEnhancedFormResidence = formikEnhancerResidence(ResidenceComponent)
export const FormResidence = ({ props, locations }) => {
  return <MyEnhancedFormResidence {...props} locations={locations} />
}

const OfficeComponent = ({
  locations,
  values,
  touched,
  errors,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
}) => {
  return (
    <div className="tab-content visible">
      {/* <span className="form-label">Retail</span> */}
      <form onSubmit={handleSubmit}>
        <div className="row row-3 mb-3 mb-md-4">
          <div className="col-md-6 col-12">
            <MySelect
              alt="location icon"
              iconSrc={icLocationBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="location"
              value={values.location}
              error={errors.location}
              touched={touched.location}
              placeholder="Office location"
              options={locations}
            />
          </div>
          <div className="col-md-6 col-12">
            <MySelect
              alt="employee icon"
              iconSrc={icEmployeeBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="employee"
              value={values.employee}
              error={errors.employee}
              touched={touched.employee}
              placeholder="Number of employees"
              options={[
                { value: "10", label: "10 employees" },
                { value: "100", label: "100 employees" },
                { value: "500", label: "500 employees" },
                { value: "1000", label: "1000 employees" },
              ]}
            />
          </div>
        </div>
        <div className="mb-3 mb-md-4">
          <span className="form-label">Facilities</span>
          <div className="select-box-wrapper">
            <div className="row row-3">
              <div className="col-md-4 col-6">
                <Checkbox
                  name="officeFacilities"
                  value="security-24-7"
                  label="Security 24/7"
                />
                <Checkbox
                  name="officeFacilities"
                  value="pasar-modern"
                  label="Fresh Modern Market"
                />
              </div>
              <div className="col-md-4 col-6">
                <Checkbox
                  name="officeFacilities"
                  value="jogging-track"
                  label="Jogging Track"
                />
                <Checkbox
                  name="officeFacilities"
                  value="taman-kota"
                  label="Green Spaced Garden"
                />
              </div>
            </div>
            {errors.officeFacilities && touched.officeFacilities && (
              <div className="invalid-feedback d-block">
                {errors.officeFacilities}
              </div>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <button className="btn btn-primary btn-block" type="submit" disabled>
            <i class="fa fa-spinner fa-spin"></i>
          </button>
        ) : (
          <button className="btn btn-primary btn-block" type="submit">
            Find Office Property
          </button>
        )}
      </form>
    </div>
  )
}
const formikEnhancerOffie = withFormik({
  mapPropsToValues({ location, employee, officeFacilities }) {
    return {
      location: location || "",
      employee: employee || "",
      officeFacilities: officeFacilities || [],
    }
  },
  handleSubmit(values) {
    setTimeout(() => {
      return navigate("/search-result/", {
        state: { values, propertyType: "Office" },
        replace: true,
      })
    }, 1000)
  },
})
const MyEnhancedFormOffice = formikEnhancerOffie(OfficeComponent)
export const FormOffice = ({ locations }) => {
  return <MyEnhancedFormOffice locations={locations} />
}

const RetailComponent = ({
  locations,
  values,
  touched,
  errors,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
}) => {
  return (
    <div className="tab-content visible">
      {/* <span className="mb-3">Office</span> */}
      <form onSubmit={handleSubmit}>
        <div className="row row-3 mb-3 mb-md-4">
          <div className="col-md-6 col-12">
            <MySelect
              alt="location icon"
              iconSrc={icLocationBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="location"
              value={values.location}
              error={errors.location}
              touched={touched.location}
              placeholder="Retail location"
              options={locations}
            />
          </div>
          <div className="col-md-6 col-12">
            <MySelect
              alt="size icon"
              iconSrc={icSizeBlack}
              className="form-icon form-select w-100"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              id="size"
              value={values.size}
              error={errors.size}
              touched={touched.size}
              placeholder="Size"
              options={[
                { value: "10x10m", label: "10x10m" },
                { value: "20x10m", label: "20x10m" },
                { value: "30x10m", label: "30x10m" },
                { value: "40x10m", label: "40x10m" },
              ]}
            />
          </div>
        </div>

        {isSubmitting ? (
          <button className="btn btn-primary btn-block" type="submit" disabled>
            <i class="fa fa-spinner fa-spin"></i>
          </button>
        ) : (
          <button className="btn btn-primary btn-block" type="submit">
            Find Retail Property
          </button>
        )}
      </form>
    </div>
  )
}

const formikEnhancerRetail = withFormik({
  mapPropsToValues({ location, size }) {
    return {
      location: location || "",
      size: size || "",
    }
  },
  handleSubmit(values) {
    setTimeout(() => {
      return navigate("/search-result/", {
        state: { values, propertyType: "Retail" },
        replace: true,
      })
    }, 1000)
  },
})
const MyEnhancedFormRetail = formikEnhancerRetail(RetailComponent)
export const FormRetail = ({ locations }) => {
  return <MyEnhancedFormRetail locations={locations} />
}

export const FormSML = ({
  locations,
  propertyTypes,
  projects,
  isLoading,
  onSubmit,
  onCheckBoxChange,
  onLocationChange,
  onPropertyTypesChange,
  onProjectsChange,
}) => {
  const { lang } = useContext(LangContext)

  return (
    <div className="tab-content visible">
      {/* <span className="form-label">Retail</span> */}
      <form onSubmit={onSubmit}>
        <div className="row row-3 mb-3 mb-md-4">
          <div className="col-md-4 col-12">
            <DefaultSelect
              alt="location icon"
              iconSrc={icLocationBlack}
              className="form-icon form-select w-100"
              id="location"
              placeholder={lang === "EN" ? "Location" : "Lokasi"}
              options={locations}
              onChange={e => onLocationChange(e)}
            />
          </div>
          <div className="col-md-4 col-12">
            <DefaultSelect
              alt="employee icon"
              iconFa="fas fa-landmark"
              className="form-icon form-select w-100"
              id="employee"
              placeholder={lang === "EN" ? "Property Types" : "Tipe Properti"}
              options={propertyTypes}
              onChange={e => onPropertyTypesChange(e)}
            />
          </div>
          <div className="col-md-4 col-12">
            <DefaultSelect
              alt="employee icon"
              iconFa="far fa-building"
              className="form-icon form-select w-100"
              id="employee"
              placeholder={lang === "EN" ? "Projects" : "Proyek"}
              options={projects}
              onChange={e => onProjectsChange(e)}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="mb-3 mb-md-4">
          <span className="form-label">
            {lang === "EN" ? "Facilities" : "Fasilitas"}
          </span>
          <div className="select-box-wrapper">
            <div className="row row-3">
              <div className="col-md-4 col-6">
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="security-24-7"
                  label="Security 24/7"
                  onChange={e =>
                    onCheckBoxChange({
                      value: "security-24-7",
                      checked: e.target.checked,
                    })
                  }
                />
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="pasar-modern"
                  label={lang === "EN" ? "Fresh Modern Market" : "Pasar Modern"}
                  onChange={e =>
                    onCheckBoxChange({
                      value: "pasar-modern",
                      checked: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-6">
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="jogging-track"
                  label="Jogging Track"
                  onChange={e =>
                    onCheckBoxChange({
                      value: "jogging-track",
                      checked: e.target.checked,
                    })
                  }
                />
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="taman-kota"
                  label={lang === "EN" ? "Green Spaced Garden" : "Taman Kota"}
                  onChange={e =>
                    onCheckBoxChange({
                      value: "taman-kota",
                      checked: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-6">
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="swimming-pool"
                  label={lang === "EN" ? "Swimming Pool" : "Kolam Renang"}
                  onChange={e =>
                    onCheckBoxChange({
                      value: "swimming-pool",
                      checked: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <button className="btn btn-primary btn-block" type="submit">
          {lang === "EN" ? "Find Property" : "Temukan Property"}
        </button>

        {/* {isSubmitting ? (
      <button className="btn btn-primary btn-block" type="submit" disabled>
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    ) : (
      <button className="btn btn-primary btn-block" type="submit">
        Find Office Property
      </button>
    )} */}
      </form>
    </div>
  )
}

export const FormSMLHome = ({
  locations,
  propertyTypes,
  projects,
  fromPrice,
  toPrice,
  isLoading,
  onSubmit,
  onCheckBoxChange,
  onLocationChange,
  onPropertyTypesChange,
  onProjectsChange,
  onFromPriceChange,
  onToPriceChange,
  valueLocation,
  valuePropertyType,
  valueMinPrice,
  valueMaxPrice,
}) => {
  const { lang } = useContext(LangContext)

  return (
    <div className="tab-content visible">
      <form onSubmit={onSubmit}>
        <div className="row row-3 mb-3 mb-md-4">
          <div className="col-md-4 col-12">
            <DefaultSelect
              alt="location icon"
              iconFa="fas fa-map-marker-alt"
              className="form-icon form-select w-100"
              id="location"
              name="location"
              placeholder={lang === "EN" ? "Location" : "Lokasi"}
              options={locations}
              onChange={e => onLocationChange(e)}
              value={locations.find(item => {return item.value === valueLocation})}
            />
          </div>
          <div className="col-md-4 col-12">
            <DefaultSelect
              alt="employee icon"
              iconFa="fas fa-landmark"
              className="form-icon form-select w-100"
              id="propertyType"
              name="propertyType"
              placeholder={lang === "EN" ? "Property Types" : "Tipe Properti"}
              options={propertyTypes}
              onChange={e => onPropertyTypesChange(e)}
              value={propertyTypes.find(item => {return item.value === valuePropertyType})}
            />
          </div>
          <div className="col-md-4 col-12">
            <DefaultSelect
              alt="employee icon"
              iconFa="far fa-building"
              className="form-icon form-select w-100"
              id="projects"
              name="projects"
              placeholder={lang === "EN" ? "Projects" : "Proyek"}
              options={projects}
              onChange={e => onProjectsChange(e)}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="row mb-3 mb-md-4">
          <div className="col-md-12 col-12">
            <span className="form-label">
              {lang === "EN" ? "Price Range" : "Kisaran Harga"}
            </span>
          </div>
          <div className="col-md-6 col-12">
            <DefaultSelect
              className="form-icon form-select w-100"
              id="fromprice"
              name="minPrice"
              placeholder={lang === "EN" ? "Min. Price (Rp)" : "Harga Minimal (Rp)"}
              options={fromPrice}
              onChange={e => onFromPriceChange(e)}
              // isLoading={isLoading}
              value={fromPrice.find(item => {return item.value === valueMinPrice})}
            />
          </div>
          <div className="col-md-6 col-12">
            <DefaultSelect
              className="form-icon form-select w-100"
              id="toprice"
              name="maxPrice"
              placeholder={lang === "EN" ? "Max. Price (Rp)" : "Harga Maksimal (Rp)"}
              options={toPrice}
              onChange={e => onToPriceChange(e)}
              // isLoading={isLoading}
              value={toPrice.find(item => {return item.value === valueMaxPrice})}
            />
          </div>
        </div>
        <div className="mb-3 mb-md-4">
          <span className="form-label">
            {lang === "EN" ? "Facilities" : "Fasilitas"}
          </span>
          <div className="select-box-wrapper">
            <div className="row row-3">
              <div className="col-md-4 col-6">
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="security-24-7"
                  label="Security 24/7"
                  onChange={e =>
                    onCheckBoxChange({
                      value: "security-24-7",
                      checked: e.target.checked,
                    })
                  }
                />
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="pasar-modern"
                  label={lang === "EN" ? "Fresh Modern Market" : "Pasar Modern"}
                  onChange={e =>
                    onCheckBoxChange({
                      value: "pasar-modern",
                      checked: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-6">
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="jogging-track"
                  label="Jogging Track"
                  onChange={e =>
                    onCheckBoxChange({
                      value: "jogging-track",
                      checked: e.target.checked,
                    })
                  }
                />
                <DefaultCheckbox
                  name="residenceFacilities"
                  value="taman-kota"
                  label={lang === "EN" ? "Green Spaced Garden" : "Taman Kota"}
                  onChange={e =>
                    onCheckBoxChange({
                      value: "taman-kota",
                      checked: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 row">
                <div className="col-xs-6 col-md-12">
                  <DefaultCheckbox
                    name="residenceFacilities"
                    value="swimming-pool"
                    label={lang === "EN" ? "Swimming Pool" : "Kolam Renang"}
                    onChange={e =>
                      onCheckBoxChange({
                        value: "swimming-pool",
                        checked: e.target.checked,
                      })
                    }
                  />
                </div>
                <div className="col-xs-6 col-md-12 p-xs-half">
                  <DefaultCheckbox
                    name="residenceFacilities"
                    value="cafetaria"
                    label={lang === "EN" ? "Cafetaria" : "Kafetaria"}
                    onChange={e =>
                      onCheckBoxChange({
                        value: "cafetaria",
                        checked: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <button className="btn btn-primary btn-block" type="submit">
          {lang === "EN" ? "Find Property" : "Temukan Property"}
        </button>

      </form>
    </div>
  )
}
